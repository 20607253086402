@import "../../../css/index.scss";

.wrapper {
  width: 26px;
  height: 18px;
  @include desktop {
    @media (max-width: 1024px) {
      width: 20px;
      height: 15px;
    }
  }
  @include mobile {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 13.33px;
      height: 9.33px;
    
  }
}
