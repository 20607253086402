@import "../../css/index.scss";

.ql-toolbar {
  background-color: $neutral-grey-200; /* Change the toolbar background color */
  border: 1px solid #ccc; /* Add a border around the toolbar */
  border-top-left-radius: 8px; /* Rounded corners */
  border-top-right-radius: 8px; /* Rounded corners */
  padding: 10px; /* Padding around the toolbar */
  width: 100%; /* Set a specific width for the editor */
  max-width: 100%; /* Prevent it from exceeding the parent container's width */
  min-height: 56px;
  max-height: fit-content;
  @include screen-lg {
    width: 100%; /* Set a specific width for the editor */
  }
  @include desktop {
    @media (max-width: 1024px) {
      min-height: 65px;
      max-height: fit-content;

    }
  }
  @include mobile{
    width: 100%;
    min-height: 65px;
    max-height: fit-content;
  }
}

.ql-container {
  width: 100%; /* Set a specific width for the editor */
  max-width: 100%; /* Prevent it from exceeding the parent container's width */
  height: 473px;
  border-color: $neutral-grey-300 !important;

  @include screen-lg {
    width: 100%; /* Set a specific width for the editor */
  }
  @include mobile{
    width: 100% !important;
  }
}

.ql-toolbar button {
  color: $neutral-black; /* Change the button text color */
  width: 28px !important;
  height: 28px !important;
  @include desktop {
    @media (max-width: 1024px) {
      width: 22px !important;
      height: 22px !important;
    }
  }
  @include mobile{
    width: 22px !important;
    height: 22px !important;
  }
}
.ql-toolbar button svg {
 
}
.ql-toolbar button:hover {
  background-color: #e0e0e0; /* Change background color on hover */
}

.ql-editor {
  min-height: 300px; /* Set a minimum height for the editor */
  border-radius: 0 0 8px 8px; /* Rounded corners at the bottom */
  padding: 10px; /* Padding inside the editor */
}

/* Change the background color of the editor */
.ql-editor.ql-blank::before {
  font-style: italic; /* Italicize placeholder text */
}
