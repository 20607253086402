@import "../../../css/index.scss";
.input {
  outline-color: $neutral-grey-300 !important;
  border-radius: 4px !important;
  border-width: 1px !important;
  border-color: $neutral-grey-300 !important;
  font-size: 16px;
  font-weight: 400 !important;
  border-style: solid !important;
  border-radius: 4px !important;
  border-width: 1px !important;
  background-color: none !important;
  width: 100% !important;
  height: 45px !important;
  display: flex !important;
  align-items: center !important;
  

.ant-input {
  height: 100%;
  padding-left: 28px !important; // Reset any padding if applied
  padding-right: 0;
  display: flex;
  align-items: center; // Ensure text aligns vertically
}

.ant-input-prefix {
  display: flex;
  align-items: center; // Align the icon in the middle vertically
  justify-content: center; // Center the icon horizontally
  height: 100%; // Ensure the icon takes the full height of the input
  padding: 0;
  margin-right: 8px !important; // Add space between the icon and input text
  position: absolute;
  top: 8%;
  bottom: 1.5px;
}

.ant-input-affix-wrapper {
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0;
  padding-left: 10px; // Optional: Adjust based on design needs
  position: relative;
}

.ant-input-affix-wrapper > input {
  height: 100%;
  line-height: 1; // Ensure the input text stays vertically aligned
 // padding-left: 28px !important;
}
}