@import "../../../../css/index.scss";

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 48px 32px 48px 32px !important;
  @include mobile {
    padding: 0px 20px 0px 20px !important;
  }
  .title {
    font-size: 24px;
    font-weight: 700;
    color: $neutral-black;
    @include mobile {
      display: none;
    }
  }
  .breadCrumb {
    .homeLink {
      display: flex;
      flex-direction: row;
      gap: 4px;
      align-items: center;
    }
    @include mobile {
      display: none;
    }
  }
  .active {
    background-color: none !important;
    color: $neutral-grey-500;
  }
}
