@import "../../css/index.scss";

input[type="checkbox"] {
  /* Customize the unchecked state */

  accent-color: #ccc; /* Default color for unchecked */
}

/* Change the color of the checkbox when checked */
input[type="checkbox"]:checked {
  accent-color: $primary-color; /* Green color for the checked state */
}
.ant-checkbox-checked .ant-checkbox-inner::after {
  border-color: white; // Change the color of the tick/checkmark
}
.ant-checkbox-checked:focus .ant-checkbox-inner:focus{
  background-color: $primary-color !important; // Change the background color when checked
  border-color: $primary-color;
}

.ant-checkbox-checked:hover .ant-checkbox-inner:hover{
  background-color: $primary-color !important; // Change the background color when checked
  border-color: $primary-color;
}
.ant-checkbox-checked .ant-checkbox-inner {
  background-color: $primary-color !important; // Change the background color when checked
  border-color: $primary-color !important;
}

/* Customize the dash/line in the intermediate (indeterminate) checkbox */
.ant-checkbox-indeterminate .ant-checkbox-inner::after {
  background-color: $primary-color; // Change the color of the dash in the indeterminate state (usually white)
  border-color: $primary-color;
  accent-color: white; // Optional: customize the dash border if necessary
}
.ant-checkbox-indeterminate .ant-checkbox-inner::after:hover .ant-checkbox-inner::after:focus {
  background-color: $primary-color !important; // Change the color of the dash in the indeterminate state (usually white)
  border-color: $primary-color !important;
  accent-color: white; // Optional: customize the dash border if necessary
}
.checkbox-wrapper {
  display: flex;
  flex-direction: column;
  gap: 12px;
  .contact-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
  .contact-info {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 12px;
    width: 100%;
    @include mobile{
      width: 100%;
    }
    .profile {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      width: 32px !important;
      height: 32px !important;
      font-size: 14px;
      @include mobile {
        width: 32px;
        height: 32px;
        font-size: 10px;
      }
      font-weight: 500;
      font-size: 10px;
      text-align: center;
      background-color: $neutral-grey-200;
      border-radius: 50%;
    }
  }
}

.contact {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.profileInfo {
  font-size: 16px !important;
  font-family: 500 !important;
  color: #101928 !important;
  @include mobile {
    font-size: 14px !important;
  }
}
.select-all {
  display: flex;
  flex-direction: row;
  gap: 12px;
}
