@import "../../../css/index.scss";
.wrapper {
  width: 26px;
  height: 26px;
  @include mobile {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 14px;
    height: 14px;
  }
}
