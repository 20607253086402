@import "../../../css/index.scss";
.wrapper {
  width: 44px;
  height: 44px;
  cursor: pointer;
  @include mobile {
    display: flex;
    align-items: center;
    justify-content: center;
    
    width: 32px;
    height: 32px;
  }
}
