@import "../../css/index.scss";
.form {
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100% !important;
  gap: 24px;
  font-size: 14px;
  font-family: 400;
  @include mobile {
    width: 100% !important;
  }
}
.otpInput {
  display: flex;
  flex-direction: row;
  justify-content: center;
 align-items: center;
 width: 100% !important;
 div{
  justify-content: center;
 }
}
.button {
  font-size: 16px;
  font-family: 500;
  background-color: $primary-color;
  color: #ffffff;
  height: 51px;
  border: 0;
  border-radius: 8px;
  width: 100% !important;
  @include mobile{
    width: 100% !important;
    height: 40px;
    font-size: 14px;
  }
  &:hover {
    background-color: $primary-color-700 !important;
    color: #ffffff !important;

    cursor: pointer;
  }
}
