@import "../../../css/index.scss";
.wrapper {
  display: flex;
  align-items: center;
  background-color: white;
  border-radius: 8px;
  width: fit-content !important;
  height: 4rem;
  @include mobile {
    font-size: 12px !important;
  }
  &.wrapperBorder {
    border: 1px solid $neutral-grey-200;
  }
  .label {
    font-weight: 400;
    color: $neutral-grey-500;
    margin-left: 0.35rem;
    white-space: nowrap;
    font-size: 14px;
    @include mobile {
      font-size: 12px;
    }
  }
  .selectContainer {
    background-color: inherit;
    border-radius: inherit;
    flex: 1;
    @include mobile {
      font-size: 12px !important;
    }
    .ant-select-selector {
      height: 100% !important;
      align-items: center;
    }

    .ant-select-selection-item {
      font-weight: 500 !important;
      @include mobile {
        font-size: 12px !important;
      }
    }

    // Target the icon directly if needed
    .ant-select-arrow {
      position: absolute; // Position the icon absolutely
      right: 12px; // Adjust as needed for spacing
      top: 45%; // Center vertically
      transform: translateY(0%); // Offset the height of the icon
    }
  }
}

:global(.ant-select-dropdown) {
  min-width: fit-content !important;
  max-width: 100% !important;
  @include mobile {
    font-size: 12px;
    min-width: fit-content !important;
    max-width: 100% !important;
  }
}
