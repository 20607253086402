@import "../../../../css/index.scss";

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: center;
  padding: 16px 16px 0 16px;
  align-content: space-between;
  padding-bottom: 32px;
  position: fixed;
  left: 0;
  background-color: #fff1ef;
  height: 100vh;

  width: 250px !important;
  @include desktop {
    @media (max-width: 1024px) {
      width: 178px !important;
    }
  }
  @include mobile {
    display: none;
    padding: 6px 20px 0px 20px;
    bottom: 0;
    background-color: #fff1ef;
    position: fixed;
    height: 100% !important;
    flex-direction: row;
    width: 100vw !important;
    justify-content: center;
    min-height: 60px;
    max-height: 60px;
    z-index: 10000;
  }
  .logo {
    cursor: pointer;
    justify-self: flex-start;
    justify-items: flex-start;
    align-self: flex-start;
    width: 108px;
    height: 33px;
    @include mobile {
      display: none;
    }
  }
  .link {
    display: flex;

    @include mobile {
      padding: 8px 12px 0px 12px;
      height: 54px !important;
      width: 56px;
      font-size: 12px;
      flex-direction: column;
      gap: 8px;
    }
  }
  a {
    transition: all 0.1s ease-in-out; // Smooth transition for all properties
    position: relative;
    text-decoration: none;
    color: #240806;
    color: #6a6767;

    font-size: 16px;
    font-weight: 500;
    display: flex;
    flex-direction: row;

    align-items: center;
    gap: 12px;
    width: 217px;
    @include desktop {
      @media (max-width: 1024px) {
        width: 154px;
        font-size: 14px;
      }
    }
    height: 48px;
    justify-items: end;
    padding: 12px 8px 12px 8px;
    &::before {
      content: "";
      width: 4px;
      height: 100%;
      background-color: transparent;
      position: absolute;
      left: 0;
      top: 0;
      color: #240806;
      z-index: -1;
    }
    &:hover::before {
      background-color: #ffffff;

      width: 100%;
      transition: all 0.3s ease-in-out; // Smooth animation for background and width
    }

    &:hover {
      border-radius: 4px;
      border-style: solid;
      border-width: 0px 0px 0px 4px;
      border-color: $primary-color;
      color: #240806;
      @include mobile {
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
        box-shadow: -2px 2px 16px 0 rgba(0, 0, 0, 0.05);

        border-width: 0px 0px 6px 0px;
      }
    }
    &.active {
      border-style: solid;
      background-color: #ffffff;
      border-width: 0px 0px 0px 4px;
      border-color: $primary-color;
      color: #240806;

      @include mobile {
        border-radius: 0;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
        box-shadow: -2px 2px 16px 0 rgba(0, 0, 0, 0.05);

        border-width: 0px 0px 6px 0px;
      }
    }
  }
  .linkWrapper {
    display: flex;
    flex-direction: column;
    gap: 22px;
    justify-content: space-between;
    transition: all 0.5s !important;
    @include mobile {
      flex-direction: row;
    }
  }
  .mainWrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    @include mobile {
      height: 100%;
      flex-direction: row;
      justify-content: end;
    }
  }
}
.wrapperMobile {
  display: none;
  flex-direction: column;
  gap: 24px;
  align-items: center;
  padding: 16px 16px 0 16px;
  align-content: space-between;
  padding-bottom: 32px;
  height: 100%;
  @include mobile {
    display: flex;
    padding: 6px 20px 0px 20px;
    bottom: 0;
    background-color: #fff1ef;
    overflow-x: auto;
    position: fixed;
    flex-direction: row;
    width: 100vw !important;
    justify-content: center;
    min-height: 70px;
    max-height: 70px;
    z-index: 1000;
  }
  .logo {
    cursor: pointer;
    justify-self: flex-start;
    justify-items: flex-start;
    align-self: flex-start;
    @include mobile {
      display: none;
    }
  }
  .link {
    display: flex;

    @include mobile {
      cursor: pointer;
      padding: 8px 12px 0px 12px;
      height: 54px !important;
     word-wrap: normal;
     word-break: keep-all;
     white-space: nowrap;

      width: fit-content;
      font-size: 12px;
      flex-direction: column;
      gap: 8px;
      align-items: center;
    }
  }
  a {
    transition: all 0.3ms ease-in-out; // Smooth transition for all properties

    text-decoration: none;
    color: #111928;
    font-size: 16px;
    font-weight: 500;
    display: flex;
    flex-direction: row;
    color: #6a6767;

    align-items: center;
    gap: 12px;
    width: 217px;
    height: 48px;
    justify-items: end;
    padding: 12px 8px 12px 8px;

    &:hover {
      border-radius: 4px;
      border-style: solid;
      background-color: #ffffff;
      border-width: 0px 0px 0px 4px;
      border-color: $primary-color;
      color: #240806;

      @include mobile {
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
        box-shadow: -2px 2px 16px 0 rgba(0, 0, 0, 0.05);

        border-width: 0px 0px 6px 0px;
      }
    }
    &.active {
      border-style: solid;
      background-color: #ffffff;
      color: #240806;

      border-width: 0px 0px 0px 4px;
      border-color: $primary-color;
      @include mobile {
        border-radius: 0;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
        box-shadow: -2px 2px 16px 0 rgba(0, 0, 0, 0.05);

        border-width: 0px 0px 6px 0px;
      }
    }
  }
  .linkWrapper {
    display: flex;
    flex-direction: column;
    gap: 22px;
    justify-content: space-between;

    @include mobile {
      gap: 15px;
      flex-direction: row;
      overflow-x: auto;

    }
  }
  .mainWrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    @include mobile {
      height: 100%;
      flex-direction: row;
      overflow-x: auto;

      //justify-content: end;
    }
  }
}
.authentication {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  @include desktop {
    @media (max-width: 1024px) {
      width: 154px;
      font-size: 14px;
    }
  }
  @include mobile {
    padding: 8px 12px 0px 12px;
    height: 54px !important;
    width: 56px;
    font-size: 12px;
    flex-direction: column;
    justify-content: center;
    gap: 8px;
    align-self: center;
    justify-self: center;
    justify-content: center;
  }
  .name {
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    @include desktop {
      @media (max-width: 1024px) {
        font-size: 13px;
      }
    }
  }
  .profile {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 12px;
    @include desktop {
      @media (max-width: 1024px) {
        gap: 8px;
      }
    }
  }
  .profilePics {
    border-radius: 50%;
    width: 24px;
    height: 24px;
  }
}
