@import "../../css/index.scss";
.upload{
    color: $primary-color;
}
.ant-upload-drag{
    background-color: beige;
}
.ant-upload-drag-icon{
    align-self: center;
}
.content{
    display: flex;
    flex-direction: column;
    align-items: center;
    @include mobile{
        gap: 4px;
    }
    .drag{
        @include mobile{
            display: none;
        }
    }
    .ant-upload-text{
        @include mobile{
            font-size: 14px !important;
            font-weight: 500 !important;
        }
    }
}