@import "../../../../css/index.scss";
.add_to_group_footer {
  width: 100%;
  @include mobile {
    border-top: 1px solid $neutral-grey-200;
    padding-top: 10px;
  }
  .addBtns {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 24px;
    width: 100%;
    @include mobile {
      width: 100%;
      flex-direction: column;
      justify-content: unset;
      gap: 12px;
    }
    .button {
      height: 51px !important;
      border: 0;
      border-radius: 8px !important;
      width: 50% !important;
      font-size: 16px !important;
      font-weight: 500;
      @include mobile {
        width: 100% !important;
        height: 40px !important;
        font-size: 14px !important;
      }
    }
    .cancelBtn {
      background-color: $brand-color;
      color: $primary-color;
      &:hover {
        background-color: $brand-color !important;
        opacity: 90%;
        color: $primary-color;

        cursor: pointer;
      }
    }
    .addContactBtn {
      background-color: $primary-color;
      color: $brand-color !important;
      &:hover {
        background-color: $primary-color-700 !important;
        color: $brand-color !important;

        cursor: pointer;
      }
    }
  }
}
.wrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100% !important;
  height: fit-content !important;
  .btn {
    height: 50px !important;
    background-color: $neutral-grey-100 !important;
    color: $primary-color !important;
    border: 1px solid $neutral-grey-500 !important;
    font-size: 16px !important;
    font-weight: 400;
    @include mobile {
      font-size: 12px !important;
      height: 40px !important;
    }
    &:hover {
      background-color: transparent !important;
      color: $primary-color !important;
    }
    &:focus {
      background-color: transparent !important;
      color: $primary-color !important;
    }
  }
  .helperText {
    font-size: 12px;
    font-weight: 400;
    color: $neutral-grey-500;
    @include mobile {
      font-size: 10px;
    }
  }
}
