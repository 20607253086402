@import "../../../../css/index.scss";
footer {
  color: $neutral-grey-100;
  padding: 80px;
  display: flex;
  flex-direction: column;
  gap: 32px;

  @include mobile {
    padding: 20px;
  }
  hr {
    color: $brand-color;
  }
  .footerWrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap-reverse;
    row-gap: 20px;
    @include mobile {
      flex-direction: column-reverse;
      gap: 32px;
    }

    .footerMain {
      display: flex;
      flex-direction: column;
      gap: 24px;
      width: 403px;
      @include mobile {
        width: 100%;
      }
      .about {
        font-size: 16px;
        font-weight: 400;
        color: #f1f2f9;
        @include mobile {
          font-size: 14px;
          line-height: 18px;
        }
      }
      .contact {
        font-size: 16px;
        font-weight: 500;
        color: #f1f2f9;

        @include mobile {
          font-size: 14px;
          line-height: 18px;
        }
      }
      .logo {
        width: 120px;
        height: 40px;
        @include mobile {
          width: 84px;
          height: 28px;
        }
      }
      .links {
        display: flex;
        flex-direction: row;
        gap: 16px;
        align-items: center;
      }
    }
    .newsletter {
      display: flex;
      flex-direction: column;
      gap: 24px;
      align-items: flex-start;
      .subscribe {
        display: flex;
        flex-direction: column;
        gap: 4px;
        h3 {
          color: #f1f2f9;
          font-weight: 700;
          font-size: 24px;
          line-height: 29px;
          @include mobile {
            font-size: 16px;
            line-height: 19px;
          }
        }
        h4 {
          color: #d1d4e6;
          font-weight: 400;
          font-size: 16px;
          line-height: 21px;
          @include mobile {
            font-size: 12px;
            line-height: 15px;
          }
        }
      }
      .form {
        padding: 0;
        display: flex;
        flex-direction: row;
        gap: 16px;
        @include mobile {
          flex-direction: column;
          width: 100%;
        }

        input {
          width: 380px;
          height: 51px;
          padding: 12px;
          border-radius: 8px;
          outline-color: $neutral-grey-300 !important;
          border-radius: 4px !important;
          border-width: 1px !important;
          border-color: $neutral-grey-300 !important;
          font-size: 16px;
          font-weight: 400 !important;
          padding: 12px !important;
          border-style: solid !important;
          outline-color: $neutral-grey-300 !important;
          border-radius: 4px !important;
          border-width: 1px !important;
          border-color: $neutral-grey-300 !important;
          font-size: 16px;
          font-weight: 400 !important;
          padding: 12px !important;
          border-style: solid !important;
          border-radius: 4px !important;
          border-width: 1px !important;
          border: 1px !important;
          outline: 1px !important;
          @include mobile {
            width: 100%;
            height: 42px;
          }
        }
        .inputError {
          border-color: $primary-color-500 !important;
          outline-color: $primary-color-500 !important;
        }
        button {
          background-color: $primary-color;
          border: 0;
          height: 51px;
          width: 128px;
          max-width: fit-content;
          border-radius: 8px;
          font-weight: 500;
          font-size: 16px !important;
          color: #ffffff;
          @include mobile {
            width: 155px;
            height: 42px;
            font-size: 14px !important;
          }
        }
      }
    }
  }
  .footerBottom {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    color: #d1d4e6;
    @include mobile{
      flex-direction: column;
      align-items: normal;
    }
    .linkGroup {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 22px;
      a {
        color: #d1d4e6;
        text-decoration: none;
        font-weight: 400;
        font-size: 14px;
        @include mobile {
          font-size: 10px;
        }
        &:hover {
          color: white;
        }
      }
    }
    .copyright {
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
      color: #d1d4e6;

      @include mobile {
        font-size: 10px;
      }
    }
  }
}
