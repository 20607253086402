.container {
  background: #fff;
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  .content {
    display: flex;
    flex-direction: row;
    gap: 4px;
    font-size: 12px;
  }
}
