@import "../../css/index.scss";
.wrapper {
  overflow-y: visible;
  display: grid;
  grid-template-areas: "main auth";
  min-height: 100vh;
  font-family: $font-neue-montreal !important;

  .logoWhite {
    display: none;
    grid-area: logo;
    @include mobile {
      display: block;
    }
    img{
      width: 92px;
      height: 30px;
    }
  }
  .main {
    padding: 10px 56px 10px 56px;
   
    grid-area: main;
    width: 50vw;
    min-height: 100vh;
    background-color: $primary-color;
    max-height: auto;
    .mainBody {
      display: flex;
      flex-direction: column;
      @include screen-lg{
        gap: 60px;
      }
      gap: 50px;
      .logo {
        cursor: pointer;
        width: 150px;
        height: 47px;
      }
      .typeboxWrapper {
        background-image: url("../../assets/images/bro.webp");
        background-repeat: no-repeat;
        background-position: top right;
        background-size:87%;
        overflow: visible;
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 558px !important;
        gap: 8em;
        > * {
          align-self: flex-start;
        }

        > :nth-child(2) {
          align-self: flex-end;
        }
        >  :nth-child(1){
          margin-top: 20px;

        }
       @include screen-lg{

       }
      }
    }
    @include mobile {
      display: none;
    }
  }
  .auth {
    grid-area: auth;
    align-items: center;
    align-content: center;
    justify-content: center;
    width: 50vw;
    @include mobile {
      align-self: flex-start;
      width: 100%;
    }
    @include screen-lg{
      width: 45vw;
    }
  }
  @include mobile {
    display: flex; // Ensure the container is set as a grid
    grid-template-areas:
      "logo"
      "auth";
    
      flex-direction: column;
    // Define the size of each row
   
   align-items: flex-start;
    grid-template-rows: 20px auto; 
    row-gap: 36px;
    width: 100% !important;
    background-color: $primary-color;
    min-height: 100vh;
    padding: 20px;
    
  }
  
}
