@import "../../../../css/index.scss";
.spin {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  margin: auto;
  @include mobile {
    height: 430px !important;
  }
}
.tags {
  width: 100%;

  border-style: solid;
  border-width: 1px;
  border-radius: 4px;
  border-color: #dcdee2;
  display: flex;
  flex-direction: row;
  align-items: center; /* Aligns items vertically */
  justify-content: flex-start; /* Aligns items horizontally, change to space-between if needed */
  padding: 12px 12px 6px 12px;
  flex-wrap: wrap; /* Allows items to wrap to the next line */
  min-height: 40px;
  row-gap: 12px; /* Space between rows */
  column-gap: 12px; /* Space between columns */
  & > :nth-child(5) {
    margin-top: 4px !important;
  }
  @include mobile{
    width: 100%;
  }
}
.wrapper {
  .title {
    font-size: 36px;
    font-weight: 700;
    text-align: center;
    color: #010516;
  }
  .subtitle {
    text-align: center;
    font-size: 16px;
    font-weight: 400;
    color: $neutral-grey-500;
  }
  .optional {
    color: $neutral-grey-300 !important;
  }
  .forms {
    display: flex;
    flex-direction: column;
    padding-bottom: 40px;
    gap: 24px;
    justify-content: center;
  }
  form {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .inputWrapper {
      display: flex;
      flex-direction: column;
      gap: 8px;
    }
    input {
      flex: 1;
      width: 100%      !important;
      height: 40px !important;
      outline-color: $neutral-grey-300;
      border-radius: 4px !important;
      border-width: 1px;
      border-color: $neutral-grey-300 !important;
      font-size: 16px;
      font-weight: 400;
      padding: 12px;
      border-style: solid;
      border-radius: 4px;
      border-width: 1px !important;
      @include mobile {
        width: 100% !important;
        font-size: 14px;
      }
    }

    .inputError {
      border-color: $primary-color-500 !important;
      outline-color: $primary-color-500 !important;
    }
    .inputLabel {
      font-size: 16px;
      font-weight: 500;
      @include mobile {
        font-size: 14px;
      }
    }
    .passwordIcon {
      position: absolute;
      left: 1120px;
      margin-top: 40px;
    }
    .errorMessage {
      font-size: 14px;
      font-weight: 400;
      color: $primary-color-500;
    }
    .inputPasswordError {
      border-color: $primary-color-500 !important;
      outline-color: $primary-color-500 !important;
    }
    .inputfirstnameError {
      border-color: $primary-color-500 !important;
      outline-color: $primary-color-500 !important;
    }
    .inputlastnameError {
      border-color: $primary-color-500 !important;
      outline-color: $primary-color-500 !important;
    }

    .addContactBtns {
      display: flex;
      flex-direction: row;
      width:100%;
      gap: 24px;
      @include mobile {
        flex-direction: column-reverse;
        width: 100% !important;
      }
    }
    .cancelBtn {
      background-color: $brand-color;
      color: $primary-color;
      &:hover {
        background-color: $brand-color !important;
        opacity: 90%;
        color: $primary-color;

        cursor: pointer;
      }
    }
    .addContactBtn {
      background-color: $primary-color;
      color: $brand-color !important;
      &:hover {
        background-color: $primary-color-700 !important;
        color: $brand-color !important;

        cursor: pointer;
      }
    }
    .button {
      padding: 16px;
      height: 51px;
      border: 0;
      border-radius: 8px;
      width: 50%;
      font-size: 16px;
      font-weight: 500;
      @include mobile {
        width: 100% !important;
        height: 41px;
      }
    }
  }
}
