@import "../../../../../css/index.scss";
.mainWrapper {
  display: flex;
  flex-direction: column;
  grid-template-areas: "message contacts";
  margin-top: 24px;

  @include mobile {
    margin-top: 0px;
  }
  row-gap: 24px;
  width: 100%;
  .main {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    column-gap: 40px;
  }
  @include screen-lg {
    column-gap: 12px;
  }
  @include desktop {
    @media (max-width: 1024px) {
      column-gap: 12px;
    }
  }
  @include mobile {
    width: 100%;
    grid-template-areas: "message";
    column-gap: 0;
    flex-direction: column;
  }
}
.contacts {
  grid-area: contacts;
  width: 45% !important;
  @include mobile {
    display: none;
  }
}
.msgHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: nowrap;
  @include mobile {
    width: 100%;
    white-space: no-wrap !important; // Prevent text from wrapping
    overflow: hidden !important; // Hide overflowed text
    text-overflow: ellipsis !important;
  }
  .title {
    color: #120d0c;

    font-size: 24px;
    font-weight: 700;
    white-space: no-wrap !important; // Prevent text from wrapping
    overflow: hidden !important; // Hide overflowed text
    text-overflow: ellipsis !important;
    @include desktop {
      @media (max-width: 1024px) {
        font-size: 30px;
      }
    }
    @include mobile {
      width: 80%;
      font-size: 20px;
      line-height: 24px;
    }
  }
  .createBtn {
    display: none;
    @include mobile {
      display: block;
      font-size: 12px;
      height: 30px;
      width: 98px !important;
      color: #ffffff;
      border-radius: 8px;
      border: 0;
      background-color: $primary-color;
    }
  }
}
.wrapper {
  display: flex;
  flex-direction: column;
  width: 55% !important;
  gap: 24px;
  grid-area: message;
  @include screen-lg {
    width: 55% !important;
  }
  @include mobile {
    width: 100% !important;
    margin-top: 0;
  }
  table {
    min-width: 100% !important;
    height: 97px !important;

    tbody {
      display: flex;
     
      flex-direction: column;
    }

    tr {
      display: flex;
      align-items: flex-start;
      column-gap: 20px; //
      margin-bottom: 5px;
    }

    @include mobile {
      min-width: 100% !important;
      max-width: 100%;
      column-gap: 10px;
    }

    .emailTitles {
      font-weight: 500;
      font-size: 16px;
      line-height: 21px;
      color: $neutral-grey-500;
      min-width: 150px !important;
      max-width: 150px !important;
      @include mobile {
        font-size: 14px;
        line-height: 16.8px;
        min-width: 120px !important;
        max-width: 120px !important;

      }
    }

    .subtitles {
      font-weight: 500;
      font-size: 16px;
      line-height: 21px;
      color: $neutral-black;
      word-break: break-all;
      word-wrap: break-word;
      white-space: normal; // Allow wrapping
      overflow: visible; // Ensure no content is hidden
      max-width: 100% !important;

      @include mobile {
        font-size: 14px;
        line-height: 16px;
      }

      p {
        white-space: normal; // Ensure wrapping inside <p>
        overflow: visible; // Prevent hidden content
        word-wrap: break-word;
      }
    }
  }
  .msg {
    border: 1px;
    border-style: solid;
    border-color: $neutral-grey-300;
    @include mobile {
      width: 100% !important;
    }
  }
  .audio {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    @include mobile {
      width: 100% !important;
    }
    audio {
      @include mobile {
        width: 100%;
      }
    }
  }
  .msgTitle,
  .msgBody {
    padding: 32px 28px 32px 28px;
    @include mobile {
      padding: 16px 12px 16px 12px;
    }
  }
  .msgBodyWrapper {
    background-color: $neutral-grey-300;
    width: 100%;
    height: 58px;

    padding: 18px 24px 18px 24px;
    @include mobile {
      height: 41px;
      padding: 12px;
    }
    h3 {
      color: #120d0c;

      font-size: 16px;
      font-weight: 500;
      @include mobile {
        font-size: 14px;
      }
    }
  }
}

.msgBody {
  width: 100%;
  min-height: fit-content !important;
  max-height: 449px !important;
  overflow-y: auto !important; /* Shows scrollbar only if needed */
  font-size: 14px;
  line-height: 20px;
  aspect-ratio: 16/9 !important;
  display: flex !important;
  flex-direction: column !important;
  color: #240806;
  a {
    text-decoration: none;
    color: #2b56ed;
  }
  @include mobile {
    font-size: 12px;
    line-height: 14px;
  }
  img {
    max-width: 100% !important;
    width: auto !important;
    height: 250px !important;
  }
}
