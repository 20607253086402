@import "../../../../../css/index.scss";
.wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 24px;
  min-height: 100%;
  .spin {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    @include mobile {
      height: 198px;
    }
  }
  @include mobile {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .mainWrapper {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }
  .editBtn {
    display: none;
    @include mobile {
      position: relative;
      top: 100%;
      gap: 8px;
      padding: 16px 12px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 42px;
      border-radius: 8px;
      background-color: $primary-color;
      font-size: 14px;
      font-weight: 500;
      color: white;
      border: 0;
      transition-duration: 1ms;
      transition-timing-function: ease-in;
    }
  }
  .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    .title {
      font-size: 24px;
      font-weight: 700;
      color: #120d0c;

      @include mobile {
        font-size: 20px;
      }
    }
    .button {
      gap: 8px;
      padding: 16px 12px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      width: 128px;
      height: 52px;
      border-radius: 8px;
      background-color: $primary-color;
      font-size: 16px;
      font-weight: 500;
      color: white;
      border: 0;
      transition-duration: 1ms;
      transition-timing-function: ease-in;
      @include mobile {
        display: none;
      }
      &:hover {
        background-color: $primary-color-700 !important;
        color: white !important;
      }
    }
  }
  .contactDetails {
    display: flex;
    flex-direction: column;
    gap: 24px;
    .profileDetails {
      display: flex;
      flex-direction: row;
      width: 355px;
      align-items: center;
      gap: 16px;
      @include mobile {
        width: 100%;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 198px;
      }
      .profilePic {
        width: 80px;
        height: 80px;
        border-radius: 50%;
        background-color: $neutral-grey-200 !important;
        display: flex;
        justify-content: center;
        align-items: center;
        @include mobile {
          width: 56px;
          height: 56px;
        }
        p {
          text-align: center;
          font-size: 20px;
          font-weight: 700;
          line-height: 24px;
          color: #101928;
          @include mobile {
            font-size: 16px;
          }
        }
      }
      .name {
        h2 {
          font-weight: 700;
          font-size: 24px;
          @include mobile{
            font-size: 18px;
          }
        }
      }
    }
  }
  .table {
    width: 335px;
    border-collapse: separate;
    border-spacing: 0 24px;
    @include mobile {
      width: 100% !important;
    }
    .tableLabel {
      color: $neutral-grey-500;
      font-size: 16px;
      font-weight: 700;
      line-height: 19px;
      @include mobile {
        font-size: 14px;
        line-height: 16px;
      }
    }
    .tableData {
      color: $neutral-black;
      font-size: 14px;
      @include mobile {
        text-align: right;
      }
      .groupNames {
        display: flex;
        flex-direction: row;
        gap: 8px;
        flex-wrap: wrap;
        @include mobile {
          justify-content: flex-end;
        }
        .groupName {
          font-size: 12px;
          background-color: $neutral-grey-200;
          padding: 0.5px 8px 0.5px 8px;
          border-radius: 8px;
        }
      }
    }
  }
}
