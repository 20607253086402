@import "../../css/index.scss";
.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 32px;
  margin-bottom: 32px;
  font-family: $font-neue-montreal !important;
  .state {
    width: 48px;
    height: 48px;
  }
  .titles {
    display: flex;
    flex-direction: column;
    text-align: center;
    gap: 8px;

    .title {
      font-size: 20px;
      font-weight: 700;
      line-height: 24px;
      color: #010516;
      @include mobile {
        font-size: 16px;
        line-height: 18px;
      }
    }
    .subtitle {
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      color: #6a6767;
      @include mobile {
        font-size: 14px;
        line-break: 16px;
      }
    }
  }
}
.button {
  width: 100%;
  height: 42px;
  background-color: #fff1ef;
  color: #d52b1e;
  font-size: 16px !important;
  font-weight: 500 !important;
  line-height: 19.2px !important;
  border: 0;
  border-radius: 8px !important;
  &:hover {
    box-shadow: none !important;

    border: 0;
    background-color: $brand-color !important;
    opacity: 90%;
    color: $primary-color !important;

    cursor: pointer;
  }
  &:focus {
    box-shadow: none !important;

    border: 0;
    background-color: darken($color: #fff1ef, $amount: 10%);
  
    color: lighten($color: $primary-color, $amount: 10%);

    cursor: pointer;
  }
}
