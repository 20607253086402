@import "../../../css/index.scss";

.wrapper {
  width: 24px;
  height: 24px;
  @include mobile {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 18.33px;
    height: 14.33px;
  }
}
