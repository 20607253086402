@import "../../../../../../css/index.scss";
.wrapper {
  display: flex;
  flex-direction: row;
  gap: 24px;
  min-width: 570px;
  height: 157px;
  justify-content: center;
  .desktop {
    display: block;
    @include mobile {
      display: none;
    }
  }
  .mobile {
    display: none;
    @include mobile {
      display: block;
    }
  }
  @include mobile {
    min-width: 100%;
    max-width: 100%;
    flex-direction: column;
    min-height: 207px;
    max-height: auto;
  }
}
