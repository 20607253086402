@import "../../css/index.scss";
.ant-modal-header {
  border-bottom: 1px solid $neutral-grey-300 !important;
}
.confirmationModalContainer {
  padding: 0 !important;
  @include mobile {
    width: 98vw !important;
    height: fit-content;
  }
  &.has-title {
    // Apply this class when the title is present
    .contentContainer {
      border-top: 1px solid $neutral-grey-300 !important; // Border applies only if title exists
    }
  }
  .contentContainer {
    flex-direction: column;
    font-weight: 500;
    gap: 3.2rem;
    padding: 24px 0 24px 0 !important;
    font-family: $font-neue-montreal !important;

    @include mobile {
      width: 100% !important;
      height: fit-content;
    }
  }
  &.has-title{
    .titleWrapper {
      padding-bottom: 24px;
    }
  }
  .titleWrapper {
    @include mobile {
      width: 100% !important;
    }
    .title {
      font-weight: 700;
      font-size: 25px;
      @include mobile {
        font-size: 20px;
        padding-top: 24px;
        padding-bottom: 12px;
      }
    }
    .subtitle {
      color: #6a6767;
      font-size: 14px;
      font-weight: 500px;
      line-height: 21px;
      @include mobile {
        font-size: 12px;
      }
    }
  }
}
.ant-modal {
  @include mobile {
    width: 100% !important;
    height: fit-content !important;
  }
}
