@import "../../../../css/index.scss";
.form {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 80vh !important;
  .scheduleWrapper {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }
  .input {
    width: 100% !important;
    height: 40px !important;
    outline-color: $neutral-grey-300 !important;
    border-radius: 4px !important;
    border-width: 1px !important;
    border-color: $neutral-grey-300 !important;
    font-size: 16px;
    font-weight: 400 !important;
    padding: 12px;
    border-style: solid !important;
    border-radius: 4px !important;
    border-width: 1px !important;
    background-color: none !important;
  }

  .addContactBtns {
    display: flex;
    flex-direction: row;
    width: 100%;
    gap: 24px;
  }
  .cancelBtn {
    background-color: $brand-color;
    color: $primary-color;
    &:hover {
      background-color: $brand-color !important;
      opacity: 90%;
      color: $primary-color;

      cursor: pointer;
    }
  }
  .addContactBtn {
    background-color: $primary-color;
    color: $brand-color !important;
    &:hover {
      background-color: $primary-color-700 !important;
      color: $brand-color !important;

      cursor: pointer;
    }
  }
  .button {
    padding: 16px;
    height: 51px;
    border: 0;
    border-radius: 8px;
    width: 50% !important;
    font-size: 16px;
    font-weight: 500;
  }
  .inputWrapper {
    display: flex;
    flex-direction: column;
    gap: 8px;
    @include mobile {
      width: 100% !important;
    }
  }
  .errorMessage {
    font-size: 14px;
    font-weight: 400;
    color: $primary-color-500;
  }
  .inputError {
    border-color: $primary-color-500 !important;
    outline-color: $primary-color-500 !important;
  }
}
.SelectWrapper {
  outline-color: $neutral-grey-200 !important;
  outline-width: 1px !important;
  outline-style: solid !important;
}
selectInput {
  width: 109px !important;
  height: 45px !important;
}
.input {
  width: 304px !important;
  height: 45px !important;
}
.filters {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 429px !important;
  gap: 16px;
}
form {
  .scheduleWrapper {
    width: 100% !important;
    .input {
      width: 100% !important;
    }
  }
  .addContactBtns {
    width: 100% !important;
    .button {
      width: 50%;
    }
  }
}
.form {
  @include mobile {
    height: fit-content !important;
    gap: 24px;
  }
  .addContactBtns {
    width: 100% !important;

    @include mobile {
      font-size: 14px;

      flex-direction: column;
    }
    .button {
      width: 50%;
      height: 51px;
      @include mobile {
        width: 100% !important;
        font-size: 14px;

        height: 40px;
      }
    }
  }
}
