@import "../../css/index.scss";
.wrapper {
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;
  gap: 16px;
  .upper{
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
  .input {
    width: 425px !important;
    @include mobile{
      width: 100% !important;
      height: 40px !important;
    }
    
  }
  @include mobile{
    min-height: 450px;
    justify-content: space-between;
  }
  .add_to_group_footer {
    position: absolute;
    top: 80%;
    @include mobile{
      position: relative;
    }
    .addBtns {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      gap: 24px;
      @include mobile{
        width: 100%;
        flex-direction: column;
        justify-content: unset;
        gap: 12px;
      }
    }
  }
  .spin {
    width: 100% !important;
    height: 100% !important;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .addContactBtns {
    display: flex;
    flex-direction: row;
    width: 468px;
    gap: 24px;
  }
  .cancelBtn {
    background-color: $brand-color;
    color: $primary-color;
    &:hover {
      background-color: $brand-color !important;
      opacity: 90%;
      color: $primary-color;

      cursor: pointer;
    }
  }
  .addContactBtn {
    background-color: $primary-color;
    color: $brand-color !important;
    &:hover {
      background-color: $primary-color-700 !important;
      color: $brand-color !important;

      cursor: pointer;
    }
  }
  .button {
    padding: 16px;
    height: 51px;
    border: 0;
    border-radius: 8px;
    width: 198px;
    font-size: 16px;
    font-weight: 500;
    @include mobile{
      width: 100%;
      height: 40px;
      font-size: 14px;
    }
  }
}
