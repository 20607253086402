@import "../../../../css/index.scss";
.spin {
  display: flex;
  margin: auto;
  width: 100% !important;
  height: 75vh !important;
  justify-content: center;
  align-items: center;
}
.helperText {
  width: 100% !important;
  @include mobile {
    width: 100% !important;
  }
}
.wrapper {
  display: flex;
  grid-template-areas: "subject body";
  column-gap: 80px;
  flex-direction: row;
  width: 100%;
  @include desktop {
    @media (max-width: 1024px) {
      column-gap: 40px;
    }
  }
  @include mobile {
    grid-template-areas:
      "subject"
      "body";
    column-gap: 0;
    row-gap: 24px;
    justify-content: center;
    flex-direction: column;
  }
  @include screen-lg {
    column-gap: 50px;
  }
  .emailTitles {
    margin-top: 24px;
    @include mobile{
      margin-top: 0;
    }
    .msgTitleWrapper {
      word-break: break-word; /* Break words only if necessary */
      overflow-wrap: break-word; /* Ensures text wraps when it overflows */
      white-space: normal;
      h1 {
        font-weight: 700;
        font-size: 24px;
        color: #120d0c;

        @include mobile {
          font-size: 20px;
        }
      }
    }
    .msgTitle {
      font-size: 24px;
      font-weight: 700;
      line-height: 28px;
      color: #120d0c;
    }
    grid-area: subject;
    width: 50%;
    display: flex;
    flex-direction: column;
    gap: 24px;
    @include mobile {
      width: 100%;
    }
    .inputWrapper {
      display: flex;
      flex-direction: column;
      gap: 8px;
      width: 100% !important;
      h5 {
        font-size: 16px;
        @include mobile {
          font-size: 14px;
        }
      }
      @include mobile {
        width: 100% !important;
      }
      .titleMsg {
        font-size: 12px;
        font-weight: 400;
        color: $neutral-grey-500;
        @include mobile {
          font-size: 10px;
        }
      }
      .errorMessage {
        font-size: 10px;
        font-weight: 400;
        color: $primary-color-500;
      }
    }
    .label {
      font-size: 14px;
      color: #240806;
      font-weight: 500;
      @include mobile {
        font-size: 12px;
      }
    }
    .helperText {
      font-size: 12px;
      font-weight: 400;
      color: $neutral-grey-500;
      @include mobile {
        font-size: 10px;
      }
    }
    .input {
      width: 100% !important;
      height: 40px !important;
      outline-color: $neutral-grey-300 !important;
      border-radius: 4px !important;
      border-width: 1px !important;
      border-color: $neutral-grey-300 !important;
      font-size: 16px;
      font-weight: 400 !important;
      //padding: 12px;
      border-style: solid !important;
      border-radius: 4px !important;
      border-width: 1px !important;
      background-color: none !important;
      @include mobile {
        width: 100% !important;
        height: 40px !important;
      }
    }
    .PhoneInput {
      padding-left: 52px !important;
    }
    .inputError {
      border-color: $primary-color-500 !important;
      outline-color: $primary-color-500 !important;
    }
  }
  .textEditor {
    width: 50%;
    grid-area: body;
    display: flex;
    flex-direction: column;
    gap: 24px;

    @include mobile {
      flex-direction: column-reverse;
      width: 100% !important;
    }
    .editorBtnGroup {
      display: flex;
      flex-direction: row;
      gap: 16px;
      font-size: 16px;
      font-weight: 500;
      line-height: 19px;
      height: 51px;
      border-radius: 8px;
      justify-content: flex-end;
      @include mobile {
        flex-direction: column;
        margin-top: 54px;
      }
      .mobileScheduleBtn {
        display: none;
        background-color: $brand-color;
        color: $primary-color;
        padding: 16px;
        border: 0;
        font-size: 16px;

        border-radius: 8px;
        @include mobile {
          display: block;
          height: 40px;
          width: 100%;
        }
      }
      .scheduleBtn {
        font-size: 16px;

        width: 98px;
        background-color: $brand-color;
        color: $primary-color;
        padding: 16px;
        border: 0;
        border-radius: 8px;
        @include mobile {
          display: none;
          width: 100%;
        }
      }
      .submitBtn {
        font-size: 16px;

        width: 123px;
        background-color: $primary-color;
        color: $brand-color;
        padding: 16px 12px 16px 12px;
        border: 0;
        border-radius: 8px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        gap: 4px;
        @include mobile {
          width: 100%;
        }
      }
    }
  }
}
.SelectWrapper {
  width: 100% !important;
  height: 40px !important;
  border-style: solid !important;
  border: 1px;
  border-color: $neutral-grey-300 !important;
  @include mobile {
    width: 100% !important;
  }
  .ant-select-dropdown {
    width: 100% !important;
  }
}
.selectInput {
  width: 100%;
  .ant-select-dropdown {
    width: 100% !important;
  }
}

.form {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 80vh !important;
  .scheduleWrapper {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }
  .input {
    width: 100% !important;
    height: 40px !important;
    outline-color: $neutral-grey-300 !important;
    border-radius: 4px !important;
    border-width: 1px !important;
    border-color: $neutral-grey-300 !important;
    font-size: 16px;
    font-weight: 400 !important;
    padding: 12px;
    border-style: solid !important;
    border-radius: 4px !important;
    border-width: 1px !important;
    background-color: none !important;
  }

  .addContactBtns {
    display: flex;
    flex-direction: row;
    width: 100%;
    gap: 24px;
  }
  .cancelBtn {
    background-color: $brand-color;
    color: $primary-color;
    &:hover {
      background-color: $brand-color !important;
      opacity: 90%;
      color: $primary-color;

      cursor: pointer;
    }
  }
  .addContactBtn {
    background-color: $primary-color;
    color: $brand-color !important;
    &:hover {
      background-color: $primary-color-700 !important;
      color: $brand-color !important;

      cursor: pointer;
    }
  }
  .button {
    padding: 16px;
    height: 51px;
    border: 0;
    border-radius: 8px;
    width: 50% !important;
    font-size: 16px;
    font-weight: 500;
  }
  .inputWrapper {
    display: flex;
    flex-direction: column;
    gap: 8px;
    @include mobile {
      width: 100% !important;
    }
  }
  .errorMessage {
    font-size: 14px;
    font-weight: 400;
    color: $primary-color-500;
  }
  .inputError {
    border-color: $primary-color-500 !important;
    outline-color: $primary-color-500 !important;
  }
}
.SelectWrapper {
  outline-color: $neutral-grey-200 !important;
  outline-width: 1px !important;
  outline-style: solid !important;
}
selectInput {
  width: 109px !important;
  height: 45px !important;
}
.input {
  width: 304px !important;
  height: 45px !important;
}
.filters {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 429px !important;
  gap: 16px;
}
form {
  .scheduleWrapper {
    .input {
      width: 100% !important;
    }
  }
  .addContactBtns {
    width: 100% !important;
    .button {
      width: 50%;
    }
  }
}
.form {
  @include mobile {
    height: fit-content !important;
    gap: 24px;
  }
  .addContactBtns {
    width: 100% !important;

    @include mobile {
      font-size: 14px;

      flex-direction: column;
    }
    .button {
      width: 50%;
      height: 51px;
      @include mobile {
        width: 100% !important;
        font-size: 14px;

        height: 40px;
      }
    }
  }
}
