@import "../../../../css/index.scss";
.wrapper{
    padding: 48px 32px 48px 32px !important;
    @include mobile{
        padding: 20px !important;
    }
    .BreadCrumb{
        @include mobile{
            display: none;
        }
    }
    .active{
        background-color: none !important;
        color: $neutral-grey-500;
    }

}