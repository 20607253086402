@import "../../../../css/index.scss";
.wrapper {
  padding: 48px 0 48px 0;
  display: flex;
  flex-direction: column;
  gap: 24px;
  @include mobile {
    padding: 0;
  }
  .mainWrapper {
    padding: 0;
    display: grid;
    grid-template-areas: "sectionOne divider sectionTwo";
    grid-template-columns: 553px 30px auto;
    @include mobile {
      grid-template-areas:
        "sectionOne"
        "sectionTwo";
      grid-template-columns: 100% 100%;
      row-gap: 24px;
    }
    .sectionOne {
      grid-area: sectionOne;
      @include mobile {
        width: 100% !important;
      }
    }
    .divider {
      grid-area: divider;
      width: 10px;
      min-height: 80vh !important;
      max-height: auto;
      @include mobile {
        display: none;
      }
    }
    .sectionTwo {
      grid-area: sectionTwo;
      @include mobile {
        width: 100%;
      }
    }
  }

  form {
    padding: 0;
  }
  .tableTitle {
    font-size: 18px;
    font-weight: 700;
    color: #120d0c;
    line-height: 21px;
    @include mobile {
      font-size: 16px;
    }
  }
  .creditQuantity {
    font-size: 14px;
    font-weight: 500;
    color: $neutral-grey-500;
    @include mobile {
      font-size: 12px;
    }
  }
  .quantity {
    font-size: 12px;
    font-weight: 500;
    color: $neutral-grey-500;
  }
  .credits {
    font-size: 16px;
    font-weight: 500;
    line-height: 19px;
    color: $neutral-black;
    @include mobile {
      font-size: 14px;
    }
  }
  .price {
    font-size: 16px;
    font-weight: 700;
    color: #120d0c;
    line-height: 19px;
    @include mobile {
      font-size: 14px;
    }
  }
  .input {
    width: 553px;
    height: 40px;
    border-radius: 6px;
    padding: 12px;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    color: $neutral-grey-500;
    outline-color: $neutral-grey-300 !important;
    border-style: solid;
    border-radius: 4px !important;
    border-width: 1px !important;
    border-color: $neutral-grey-300 !important;
    @include mobile {
      width: 100% !important;
    }
  }
  .inputError {
    border-color: $primary-color-500 !important;
    outline-color: $primary-color-500 !important;
  }
  .inputWrapper {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
  .inputLabel {
    font-size: 16px;
    font-weight: 500;
    @include mobile {
      font-size: 14px;
    }
  }
  .titles {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
  .title {
    font-size: 24px;
    font-weight: 700;
    color: #120d0c;
    line-height: 28px;
    @include mobile {
      font-size: 20px;
    }
  }
  .subtitle {
    font-size: 16px;
    font-weight: 400;
    color: #120d0c;
    line-height: 20px;
    @include mobile {
      font-size: 14px;
    }
  }
  .Alert {
    width: 553px;
  }
  .inputCredit {
    width: 553px;
    height: 40px;

    padding: 12px;
    outline-color: #dcdee2;
    border-color: #dcdee2;
    border-style: solid;
    border-radius: 4px;
    border-width: 1px !important;
  }
  .Splitter {
    height: 560px !important;
    width: 100% !important;
  }
  .table {
    width: 347px;
    width: 100% !important;
    hr {
      width: 347px !important;
      @include mobile {
        width: 100% !important;
      }
    }
  }
  .sectionTwo,
  .sectionOne {
    display: flex;
    flex-direction: column;
    gap: 32px;
  }
  .applyWrapper {
    width: 347px;
    display: flex;
    flex-direction: row;
    @include mobile {
      width: 100% !important;
    }
    gap: 12px;
    align-items: center;
    .activeApplyBtn {
      background-color: $neutral-black;
      color: white;
    }
    input {
      min-width: 270px;
      max-width: 100%;
      height: 40px;
      font-size: 14px;

      font-weight: 400;
      line-height: 18px;
      color: $neutral-grey-500;
      outline-color: $neutral-grey-300 !important;
      border-style: solid;
      border-radius: 4px !important;
      border-width: 1px !important;
      border-color: $neutral-grey-300 !important;
    }
  }
  .applyBtn {
    width: 65px;
    height: 43px;
    border-radius: 8px;
    color: $neutral-grey-400;
    font-size: 16px;
    font-weight: 500;
    line-height: 19px;
    background-color: $neutral-grey-200;
    @include mobile {
      font-size: 14px;
    }
  }
  .leftAlign {
    text-align: left; /* Ensures text is aligned left */
  }

  /* Optional: Flexbox for better control over inner elements */
  .priceDetails {
    display: flex;
    flex-direction: column; /* Stack h5 and p vertically */
    align-items: flex-end; /* Aligns content to the left */
  }
  .priceDiscount {
    display: flex;
    flex-direction: column; /* Stack h5 and p vertically */
    align-items: flex-start;
  }
  .button {
    width: 347px;
    height: 43px;
    border-radius: 8px;
    background-color: $primary-color;
    font-size: 16px;
    font-weight: 500;
    color: white;
    border: 0;
    transition-duration: 1ms;
    transition-timing-function: ease-in;
    @include mobile {
      width: 100%;
      font-size: 14px;
    }
    &:hover {
      background-color: $primary-color-700 !important;
      color: white !important;
    }
  }
  .panelOne {
    min-width: 573px !important;
    padding-right: 14px;
  }
  .panelTwo {
    padding-left: 24px;
  }
}
