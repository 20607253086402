@import "../../../css/index.scss";

.wrapper {
  display: flex;
  flex-direction: column;
  height: 100% !important;
  width: 100% !important;
  padding: 24px 80px 0px 30px;
  align-items: center;
  gap: 120px;
  .titles{
    display: flex;
    flex-direction: column;
    gap: 12px;
    @include mobile{
      gap: 8px;
    }
  }
  
  .main {
    display: flex;
    flex-direction: column;
    gap: 64px;
    width: 100% !important;
    justify-content: center;
    align-items: center;
    @include mobile{
      gap: 32px;
    }
  }
  .navigate {
    cursor: pointer;
    align-self: flex-start;
  }
  @include mobile{
    padding: 12px;
  }
  .title {
    font-size: 36px;
    font-weight: 700;
    text-align: center;
    color: #010516;
    @include mobile {
      font-size: 24px;
    }
  }
  .titles {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
  .subtitle {
    text-align: center;
    font-size: 16px;
    font-weight: 400;
    color: $neutral-grey-500;
    @include mobile {
      font-size: 14px;
    }
  }
  form {
    padding: 0;
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: 100% !important;
    @include mobile{
      width: 100%;
    }
    .inputWrapper {
      display: flex;
      flex-direction: column;
      gap: 8px;
      width: 100% !important;
    }
    .input {
      flex: 1;
      width: 100% !important;
      height: 48px;
      outline-color: $neutral-grey-300;
      border-radius: 4px !important;
      border-width: 1px;
      border-color: $neutral-grey-300 !important;
      font-size: 16px;
      font-weight: 400;
      padding: 12px;
      border-style: solid;
      border-radius: 4px;
      border-width: 1px !important;
      @include mobile {
        width: 100% !important;
        height: 40px !important;
      }
    }

    .inputError {
      border-color: $primary-color-500 !important;
      outline-color: $primary-color-500 !important;
    }
    .inputLabel {
      font-size: 16px;
      font-weight: 500;
      @include mobile{
        font-size: 14px;
      }
    }
    .passwordIcon {
      position: absolute;
      margin-left: 450px;
      margin-top: 40px;
      @include screen-sm {
        margin-left: 320px;
      }
      @include mobile {
        margin-left: 220px;
      }
    }
    .errorMessage {
      font-size: 14px;
      font-weight: 400;
      color: $primary-color-500;
    }
    .inputPasswordError {
      border-color: $primary-color-500 !important;
      outline-color: $primary-color-500 !important;
    }

    .button {
      background-color: $primary-color;
      color: #ffffff;
      height: 51px;
      border: 0;
      border-radius: 8px;
      width: 100% !important;
      font-size: 16px;
      font-weight: 500;
      &:hover {
        background-color: $primary-color-700 !important;
        color: #ffffff !important;

        cursor: pointer;
      }
      @include mobile {
        width: 100% !important;
        height: 40px !important;
      }
    }
  }
  @include mobile {
    background-color: white;
    justify-content: center;
    border-radius: 12px;
    justify-content: center;
    align-items: normal;
    gap: 36px;
    height: fit-content !important;
    width: 100%;
    padding: 32px 21px 26px 21px !important;

  }
}
