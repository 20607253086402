.wrapper{
    background-image: linear-gradient(to right,#FFFFFF66, #FFFFFF29);
    height: 98px;
    width: 335px;
    border-radius: 12px;
    padding: 16px;
    display: flex;
    flex-direction: column;
    gap: 2px;
    .body{
        color: #FFFFFF;
        font-size: 14px;
        font-weight: 400;
    }
    .title{
        color: #FFFFFF;
        font-size: 18px;
        font-weight: 700;
    }
    
}