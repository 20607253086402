@import "../../css/index.scss";

.wrapper {
  min-height: 100vh;
  width: 100%;
  display: grid;
  position: relative;
  grid-template-areas:
    "sidebar header"
    "sidebar main"
    "sidebar disclaimer";
  grid-template-columns: 250px minmax(0, auto);
  font-family: $font-neue-montreal !important;
  @include desktop {
    @media (max-width: 1024px) {
      column-gap: normal;
      grid-template-columns: 178px minmax(0, 846px);
    }
  }
  grid-template-rows: 70px minmax(0px, auto);
  @include mobile {
    grid-template-columns: none;
    row-gap: 15px !important;
    position: relative;
    grid-template-areas:
      "header"
      "main"
      "sidebar"
      "disclaimer";
  }
  .disclaimer {
    position: absolute;
    grid-area: disclaimer;
    bottom: 0px !important;
    width: 100%;
    color: $neutral-grey-400 !important; // Default tab color
    font-size: 12px !important;
    font-weight: 500 !important;
    //border-top: 1px solid $neutral-grey-300;
    text-align: center;
    height: 30px;
    padding: 10px;
    margin-bottom: 0;
    @include mobile {
      font-size: 10px !important;
    }
    a {
      color: $primary-color;
      text-decoration: none;
    }
  }
  .sidebar {
    grid-area: sidebar;
    //z-index: 10000 !important;
    @include mobile {
      min-height: fit-content;
      max-height: fit-content;
      background-color: transparent;
    }
  }
  .header {
    grid-area: headers;
    border-color: $neutral-grey-300;
    height: 72px;
    @include mobile {
      width: 100vw;
    }
  }
  .main {
    grid-area: main;
    width: 100% !important;
    @include mobile {
      overflow-y: hidden;
      width: 100vw;

      max-height: auto;
      margin-bottom: 45px;
    }
  }
}
