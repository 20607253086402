@import "../../../../css/index.scss";
.waveformContainer {
  height: 72px !important;
}
.spin {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  width: 100%;
  height: 50vh;
}
.wrapper {
  display: flex;
  grid-template-areas: "subject body";
  flex-direction: row;
  column-gap: 40px;
  .helperText {
    width: 100% !important;
    @include mobile {
      width: 100% !important;
    }
  }
  @include mobile {
    grid-template-areas:
      "subject"
      "body";
    column-gap: 0;
    row-gap: 24px;
    justify-content: center;
    flex-direction: column;
  }
  @include screen-lg {
    column-gap: 50px;
  }
  .emailTitles {
    margin-top: 24px;
    @include mobile {
      margin-top: 0;
    }
    .msgTitleWrapper {
      word-break: break-word; /* Break words only if necessary */
      overflow-wrap: break-word; /* Ensures text wraps when it overflows */
      white-space: normal;
      h1 {
        font-weight: 700;
        font-size: 24px;
        color: #120d0c;

        @include mobile {
          font-size: 20px;
        }
      }
    }
    .msgTitle {
      font-size: 24px;
      font-weight: 700;
      line-height: 28px;
      color: #120d0c;
    }
    grid-area: subject;
    width: 50%;
    display: flex;
    flex-direction: column;
    gap: 24px;
    @include mobile {
      width: 100%;
    }
    .label {
      font-size: 14px;
      color: #240806;
      font-weight: 500;
      @include mobile {
        font-size: 12px;
      }
    }

    .inputWrapper {
      display: flex;
      flex-direction: column;
      gap: 8px;
      width: 100% !important;
      :global(.custom-segmented .ant-segmented-item-selected) {
        background-color: $primary-color !important;
        color: $brand-color !important;
        font-weight: bold !important;
        border-radius: 4px;
        height: 100% !important;

        //transition: all 0.3s ease;
        @include mobile {
          font-size: 12px !important;
        }
      }
      :global(.custom-segmented .ant-segmented-item) {
        width: 50% !important;
      }
      @include mobile {
        width: 100% !important;
      }
      .titleMsg {
        font-size: 12px;
        font-weight: 400;
        color: $neutral-grey-500;
        @include mobile {
          font-size: 10px;
        }
      }
      .errorMessage {
        font-size: 10px;
        font-weight: 400;
        color: $primary-color-500;
      }
    }
    .helperText {
      font-size: 12px;
      font-weight: 400;
      color: $neutral-grey-500;
      @include mobile {
        font-size: 10px;
      }
    }
    .input {
      width: 100% !important;
      height: 40px !important;
      outline-color: $neutral-grey-300 !important;
      border-radius: 4px !important;
      border-width: 1px !important;
      border-color: $neutral-grey-300 !important;
      font-size: 16px;
      font-weight: 400 !important;
      //padding: 12px;
      border-style: solid !important;
      border-radius: 4px !important;
      border-width: 1px !important;
      background-color: none !important;
      @include mobile {
        width: 100% !important;
        height: 40px !important;
        font-size: 14px;
      }
    }
    .PhoneInput {
      padding-left: 52px !important;
    }
    .inputError {
      border-color: $primary-color-500 !important;
      outline-color: $primary-color-500 !important;
    }
  }
  .textEditor {
    width: 50%;
    grid-area: body;
    display: flex;
    flex-direction: column;
    gap: 24px;

    @include mobile {
      flex-direction: column-reverse;
      width: 100%;
    }
  }
}
.SelectWrapper {
  width: 100% !important;
  height: 40px !important;
  border-style: solid !important;
  border: 1px;
  border-color: $neutral-grey-300 !important;
  @include mobile {
    width: 100% !important;
  }
}
.selectInput {
  width: 100%;
}
.editorBtnGroup {
  display: flex;
  flex-direction: row;
  gap: 16px;
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  height: 51px;
  border-radius: 8px;
  justify-content: flex-end;
  @include mobile {
    flex-direction: column;
    margin-top: 54px;
  }
  .mobileScheduleBtn {
    display: none;
    background-color: $brand-color;
    color: $primary-color;
    padding: 16px;
    border: 0;
    font-size: 16px;

    border-radius: 8px;
    @include mobile {
      display: block;
      height: 40px;
      width: 100%;
    }
  }
  .scheduleBtn {
    width: 98px;
    background-color: $brand-color;
    color: $primary-color;
    padding: 16px;
    border: 0;
    font-size: 16px;

    border-radius: 8px;
    @include mobile {
      display: none;
      width: 100%;
    }
  }
  .submitBtn {
    width: 123px;
    background-color: $primary-color;
    color: $brand-color;
    padding: 16px 12px 16px 12px;
    border: 0;

    border-radius: 8px;
    display: flex;        font-size: 16px;

    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 4px;
    @include mobile {
      width: 100%;
    }
  }
}
.audio {
  position: relative;
  width: 100% !important;
  border-width: 1px;
  border-style: solid;
  border-color: $neutral-grey-300;
  border-radius: 8px;
  height: 328px;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  overflow: hidden;
  .cardTitle {
    justify-self: flex-start !important;
    align-self: flex-start !important;
    position: absolute;
    top: 0;
    left: 0;
    width: 100% !important;
    border-bottom: 1px solid $neutral-grey-300;
    :global(.custom-segmented .ant-segmented-item-selected) {
      background-color: $primary-color !important;
      color: $brand-color !important;
      font-weight: bold !important;
      border-radius: 4px;
      height: 100% !important;
      //transition: all 0.3s ease;
      @include mobile {
        font-size: 12px !important;
      }
    }
    :global(.custom-segmented .ant-segmented-item) {
      width: 50% !important;
    }

    :global(.custom-segmented .ant-segmented-item-selected:hover) {
      background-color: $primary-color !important;
      color: $brand-color !important;
      font-weight: bold !important;
      border-radius: 4px;
      //transition: all 0.3s ease;
    }
  }
  .cardBody {
    display: flex;

    justify-content: center;
    align-items: center;
    align-self: center;
    margin: auto;

    &.animate {
      /* Class to trigger the animation */
      animation: animate 0.5s ease-out;
    }
    &.reverseAnimate {
      animation: reverseAnimate 0.5s ease-out;
    }
  }
  @include screen-lg {
    width: 100% !important;
  }
  @include mobile {
    width: 100% !important;
  }
  .audio-recorder-container {
    width: 100% !important;
  }
}
.form {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 80vh !important;
  .scheduleWrapper {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }
  .input {
    width: 100% !important;
    height: 40px !important;
    outline-color: $neutral-grey-300 !important;
    border-radius: 4px !important;
    border-width: 1px !important;
    border-color: $neutral-grey-300 !important;
    font-size: 16px;
    font-weight: 400 !important;
    padding: 12px;
    border-style: solid !important;
    border-radius: 4px !important;
    border-width: 1px !important;
    background-color: none !important;
    @include mobile {
      width: 100% !important;
      font-size: 14px;
    }
  }

  .addContactBtns {
    display: flex;
    flex-direction: row;
    width: 100% !important;
    gap: 24px;
  }
  .cancelBtn {
    background-color: $brand-color;
    color: $primary-color;
    width: 50% !important;
    &:hover {
      background-color: $brand-color !important;
      opacity: 90%;
      color: $primary-color;

      cursor: pointer;
    }
  }
  .addContactBtn {
    background-color: $primary-color;
    color: $brand-color !important;
    &:hover {
      background-color: $primary-color-700 !important;
      color: $brand-color !important;

      cursor: pointer;
    }
  }
  .button {
    padding: 16px;
    height: 51px;
    border: 0;
    border-radius: 8px;
    width: 198px;
    font-size: 16px;
    font-weight: 500;
  }
  .inputWrapper {
    display: flex;
    flex-direction: column;
    gap: 8px;
    @include mobile {
      width: 100% !important;
    }
  }
  .errorMessage {
    font-size: 14px;
    font-weight: 400;
    color: $primary-color-500;
  }
  .inputError {
    border-color: $primary-color-500 !important;
    outline-color: $primary-color-500 !important;
  }
}
.form {
  @include mobile {
    height: fit-content !important;
    gap: 24px;
  }
  .addContactBtns {
    width: 100% !important;

    @include mobile {
      font-size: 14px;

      flex-direction: column;
    }
    .button {
      width: 50%;
      height: 51px;
      @include mobile {
        width: 100% !important;
        font-size: 14px;

        height: 40px;
      }
    }
  }
}
@keyframes animate {
  from {
    transform: translateX(-30px);
  }
  to {
    transform: translateX(0px);
  }
}

@keyframes reverseAnimate {
  from {
    transform: translateX(30px);
  }
  to {
    transform: translateX(0px);
  }
}
