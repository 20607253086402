@import "../../../../../../css/index.scss";

.loaderWrapper {
  width: 100% !important;
  height: 100% !important;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}
.mainWrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-content: space-between;
  gap: 24px;
  height: 100%;
  .buttons {
    justify-self: flex-end;
    display: flex;
    flex-direction: row;
    gap: 16px;
    align-items: center;
    @include mobile {
      gap: 8px;
    }
    .cancelBtn {
      background-color: $brand-color;
      border-radius: 8px;
      padding: 16px;
      height: 51px;
      width: 100%;
      color: $primary-color;
      font-size: 16px;
      font-weight: 500;
      border: 0;
      &:hover {
        background-color: darken($brand-color, 10%) !important;
      }
      @include mobile {
        height: 40px;
        font-size: 12px;
      }
    }

    .submitBtn {
      font-size: 16px;
      font-weight: 500;
      border: 0;
      background-color: $primary-color-500;
      color: #ffffff;
      width: 100%;

      height: 51px;
      &:hover {
        background-color: darken($primary-color-500, 10%) !important;
      }
      @include mobile {
        height: 40px;
        font-size: 12px;
      }
    }
  }
}
.wrapper {
  border: 1px solid #d1d4e6;
  border-radius: 8px;
  .main {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 32px 28px 32px 28px;
    .head {
      display: flex;
      flex-direction: row;
      gap: 48px;
      .title {
        color: #575b75;
        font-size: 16px;
        font-weight: 500;
        width: 81px;
      }
      .subtitle {
        color: #120d0c;
        font-size: 16px;
        font-weight: 500;
      }
    }
  }
  .responseTitle {
    background-color: #d1d4e6;
    padding: 18px 24px 18px 24px;
    h6 {
      color: #000000;
      font-weight: 500;
      font-size: 16px;
    }
  }
  .response {
    padding: 32px 28px 32px 28px;
    p {
      color: #575b75;
      font-size: 14px;
      font-family: 400;
    }
  }
}
