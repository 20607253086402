@import "../../css/index.scss";
.header {
  padding-top: 20px !important;
  top: 0;
  width: 100% !important;
  padding: 0;
  opacity: 910%;
  height: auto;
  align-self: center;
  width: 100%;
  justify-self: center;
  justify-items: center;
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  z-index: 999;
  backdrop-filter: blur(10px);
  position: fixed;
  background-color: transparent;
  @include mobile {
    padding-top: 0 !important;
    top: 0;
    height: auto;
    backdrop-filter: blur(10px);
    background-color: #fff1ef66;
  }
}
