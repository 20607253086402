.ant-pagination-item {
    display: none !important; /* Hide the page number box */
  }
  
  .ant-pagination-prev,
  .ant-pagination-next {
    display: inline-block; /* Keep only the previous and next arrows */
  }
  
  .ant-pagination {
    display: inline-flex; /* Align arrows inline */
  }
  