@import "../../../../../../css/index.scss";
.wrapper {
  padding: 8px 16px 8px 16px;
  background-color: #fbfcfd;
  border: 1px solid #f1f2f9;
  border-radius: 4px;
  width: 100%;
  min-height: 155px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  .header {
    display: flex;
    flex-direction: column;
    gap: 8px;
    .title {
      display: flex;
      gap: 8px;
      flex-direction: row;
      color: #120d0c;
      align-items: center;
      .titleIcon {
        width: 20px;
        height: 20px;
      }
      h6 {
        font-size: 16px;
        font-weight: 500;
        @include mobile {
          font-size: 14px;
        }
      }
    }

    .subtitle {
      color: #575b75;
      font-size: 16px;
      font-weight: 400;
      @include mobile {
        font-size: 14px;
      }
    }
  }
  .keywords {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 16px;
    align-items: center;
    .keywordBtn {
      padding: 8px 12px 8px 12px;
      flex-direction: row;
      display: flex;
      justify-content: center;
      gap: 6px;
      border-radius: 20px;
      border: 1px solid #dee0ed;
      .radio {
        border: 2px solid #cccdd0;
        border-radius: 100%;
        width: 14px;
        height: 14px;
      }
      .active {
        border-color: #ffffff;
      }
    }
    .active {
      border-color: #ea958e;
      background-color: $primary-color;
      color: #ffffff;
    }
  }
}
