@import "../../css/index.scss";
.wrapper {
  //backdrop-filter: blur(10px);
  height: max-content !important;
  text-align: center;
  width: inherit !important;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  flex-direction: column;
  .title {
    text-align: center;
    //color: $primary-color;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: #010516;
  }
  .subtitle {
    font-size: 16px;
    line-height: 20px;
    font-weight: 400;
    color: #120d0c;
  }
}
