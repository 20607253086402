@import "../../../css/index.scss";
.wrapper{
    width: 26px;
    height: 21px;
    @include mobile {
      display: flex;
      align-items: center;
      justify-content: center;
          width: 10.33px;
          height: 13.33px;
        
      }
}