@import "../../../../css/index.scss";
.wrapper{
    display: flex;
    flex-direction: column;
    gap: 8px;
    max-width: 80% !important;
    @include mobile{
        gap: 4px;
    }
    p{
        font-size: 14px;
        color: $neutral-black;
        font-weight: 500;
        text-align: center;
        @include mobile{
            font-size: 12px;
        }
    }
}