@import "../../../css/index.scss";

.wrapper {
  justify-self: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  gap: 64px;
  padding: 64px 80px 24px 30px;
  
  .titles{
    gap: 12px;
    @include mobile{
      gap: 8px;
    }
  }
  .title {
    font-size: 36px;
    font-weight: 700;
    text-align: center;
    color: #010516;
    @include mobile {
      font-size: 24px;
    }
  }
  .titles {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
  .subtitle {
    text-align: center;
    font-size: 16px;
    font-weight: 400;
    color: $neutral-grey-500;
    @include mobile {
      font-size: 14px;
    }
  }
  form {
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: 100% !important;
    @include mobile{
      width: 100% !important;
    }
    
    .inputWrapper {
      display: flex;
      flex-direction: column;
      gap: 8px;
      width: 100% !important;
      @include mobile {
        width: 100% !important;
      }
    }
    .input:focus {
      background-color: transparent !important;
    }
    .input:not(:placeholder-shown) {
      background-color: transparent !important;
    }
    .input {
      
      width: 100% !important;
      height: 48px !important;
      outline-color: $neutral-grey-300 !important;
      border-radius: 4px !important;
      border-width: 1px !important;
      border-color: $neutral-grey-300 !important;
      font-size: 16px;
      font-weight: 400 !important;
      padding: 12px !important;
      border-style: solid !important;
      border-radius: 4px !important;
      border-width: 1px !important;
      @include mobile {
        width: 100% !important;
        height: 40px !important;
      }
      input:focus {
        background-color: transparent !important;
      }
      .input:focus,
      .input:hover {
        background-color: transparent !important;
        box-shadow: none !important;
      }
    }

    .inputError {
      border-color: $primary-color-500 !important;
      outline-color: $primary-color-500 !important;
    }
    .inputLabel {
      font-size: 16px;
      font-weight: 500;
      @include mobile{
        font-size: 14px;
      }
    }
    .passwordIcon {
      position: absolute;
      left: 1020px;
      margin-top: 40px;
      cursor: pointer;
      @include mobile {
        margin-left: 550px;
      }
      @include screen-sm {
        margin-left: 150px;
      }
    }
    .errorMessage {
      font-size: 12px;
      font-weight: 400;
      color: $primary-color-500;
    }
    .inputPasswordError {
      border-color: $primary-color-500 !important;
      outline-color: $primary-color-500 !important;
    }
    .inputfirstnameError {
      border-color: $primary-color-500 !important;
      outline-color: $primary-color-500 !important;
    }
    .inputlastnameError {
      border-color: $primary-color-500 !important;
      outline-color: $primary-color-500 !important;
    }
    .siginWithGoogle {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      border-style: solid;
      border-width: 1px;
      border-color: #dcdee2;
      border-radius: 6px;
      height: 51px;
      width: 482px;

      cursor: pointer;
      h5 {
        font-size: 16px;
        font-weight: 500;
        @include mobile {
          font-size: 14px;
          font-weight: 400;
        }
      }
      .google {
        width: 18px;
        height: 18px;
      }
      gap: 10px;
      @include mobile {
        width: 100% !important;
        height: 40px !important;
      }
    }
    .button {
      background-color: $primary-color;
      color: #ffffff;
      height: 51px;
      border: 0;
      border-radius: 8px;
      width: 100%;
      font-size: 16px;
      font-weight: 500;
     
      &:hover {
        background-color:$primary-color-700 !important;
        color: #ffffff !important;

        cursor: pointer;
      }
      @include mobile {
        width: 100%;
        height: 40px !important;
        font-size: 14px;
        font-weight: 500;
      }
    }
    .links {
      color: $primary-color;
      text-decoration: none;
    }
    .createAccount {
      display: flex;
      flex: 1;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      gap: 5px;
      font-size: 14px;
      font-weight: 400;
      width: 100%;
      height: 51px;
      color: #6A6767;

      @include mobile {
        width: 100% !important;
        height: 40px !important;
      }
    }
    .siginWithApple {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      border-style: solid;
      border-width: 1px;
      border-color: #dcdee2;
      border-radius: 6px;
      cursor: pointer;
      width: 482px;
      height: 51px;
      gap: 5px;
      h5 {
        font-size: 16px;
        font-weight: 500;
        @include mobile {
          font-size: 14px;
          font-weight: 400;
        }
      }
      @include mobile {
        width: 100% !important;
        height: 40px !important;
      }
    }
    .alternative {
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 482px;
      height: 51px;

      .or {
        flex: 1;
        text-align: center;
        position: relative;

        &::before {
          content: "";
          position: absolute;
          left: 0;
          width: 40%;
          height: 1px;
          background-color: $neutral-grey-300;
          top: 50%;
          transform: translateY(-50%);
        }

        &::after {
          content: "";
          position: absolute;
          right: 0;
          width: 40%;
          height: 1px;
          background-color: $neutral-grey-300;
          top: 50%;
          transform: translateY(-50%);
        }
      }
      @include mobile {
        width: 100% !important;
        height: 40px !important;
      }
    }
    @include mobile {
      width: 100% !important;
    }
  }
  @include mobile {
    background-color: white;
    align-self: flex-start;
    justify-self: flex-start;
    border-radius: 12px;
    width: 100%;
    padding: 32px 21px 26px 21px;
    gap: 36px;
  }
}
.forgot {
  display: flex;
  flex-direction: row;
  justify-content: end;
  font-size: 16px;
  font-weight: 500;
  width: 100% !important;
  @include mobile{
    width: 100% !important;
    font-size: 14px;
  }
}
