@import "../../../css/index.scss";
.wrapper {
  display: flex;
  flex-direction: column;
  height: 100% !important;
  width: 100% !important;
  padding: 24px 80px 0px 30px;
  align-items: center;
  gap: 120px;
  .titles {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }
  .main {
    display: flex;
    flex-direction: column;
    gap: 64px;
    width: 100% !important;
    justify-content: center;
  }
  .navigate {
    cursor: pointer;
    align-self: flex-start;
  }
  .mainContent {
    display: flex;
    flex-direction: column;
    gap: 64px;
    width: 100% !important;
    justify-content: center;
    @include mobile {
      width: 100% !important;
      gap: 36px;
    }
  }
  .title {
    font-size: 36px;
    font-weight: 700;
    text-align: center;
    color: #010516;
    @include desktop {
      @media (max-width: 1024px) {
        font-size: 30px;
      }
    }
    @include mobile {
      font-size: 24px;
    }
  }
  .titles {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
  .subtitle {
    text-align: center;
    font-size: 16px;
    font-weight: 400;
    color: $neutral-grey-500;
    @include mobile {
      font-size: 14px;
    }
  }
  .createAccount {
    flex: 1;
    text-align: center;
    gap: 5px;
    font-size: 14px;
    font-weight: 400;
    width: 100% !important;
    color: #6A6767;
    .links {
      cursor: pointer;
    }

    @include mobile {
      font-size: 12px;
    }
  }
  .links {
    color: $primary-color;
    text-decoration: none;
  }
  .subtitle {
    text-align: center;
    font-size: 16px;
    font-weight: 400;
    color: $neutral-grey-500;
  }
  .form {
    display: flex;
    flex-direction: column;
    justify-content: center !important;
    align-items: center;
    width: 100% !important;
    gap: 24px;
    .inputWrapper {
      width: 100% !important;
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 8px;
    }
  }
  @include mobile {
    background-color: white;
    justify-content: center;
    border-radius: 12px;
    justify-content: center;
    align-items: center;
    gap: 60px;
    height: fit-content !important;
    width: 100% !important;
    padding: 32px 21px 26px 21px !important;
    gap: 36px;
  }
}
