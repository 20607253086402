@import "./breakpoints.scss";
@import "./colors.scss";
@import "./mixins.scss";

@font-face {
  font-family: "Neue Montreal";
  src: url("../assets/fonts/NeueMontreal-Bold.otf") format("opentype");
  font-weight: 800;
  font-style: normal;
}
@font-face {
  font-family: "Neue Montreal";
  src: url("../assets/fonts/NeueMontreal-Medium.otf") format("opentype");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "Neue Montreal";
  src: url("../assets/fonts/NeueMontreal-Medium.otf") format("opentype");
  font-style: normal;
}
@font-face {
  font-family: "Neue Montreal";
  src: url("../assets/fonts/NeueMontreal-Regular.otf") format("opentype");
  font-weight: 400;
  font-style: normal;
}

:root {
  font-family: "Neue Montreal" !important;
  font-size: 16px;
}
$font-neue-montreal: "Neue Montreal";

/* Global font setup */
:root {
  font-family: "Neue Montreal", sans-serif;
  font-size: 16px; /* Base font size */
}

html {
  font-family: $font-neue-montreal !important;
}
* {
  box-sizing: border-box;
  margin: 0px;
  padding: 0px;
}
/* Custom styles to reset Ant Design input */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
  background-color: #f0f0f0 !important;
}
button {
  cursor: pointer;
}
html {
  scroll-behavior: smooth; /* Enables smooth scrolling for the entire document */
}
