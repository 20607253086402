@import "../../css/index.scss";
.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
  .iconWrapper {
    width: 120px;
    height: 120px;
    background-color: $brand-color;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
  }
  .audioPlayer {
    display: flex;
    flex-direction: row;
    gap: 12px;
    //max-width: 97% !important;
    @include desktop {
      @media (max-width: 1100px) {
        flex-direction: column;
        align-items: center;
      }
    }
    @include mobile{
      flex-direction: column;
      align-items: center;
    }
    audio{
      @include mobile{
        width: 300px !important;
      }
    }
    .discardBtn {
      width: 84px;
      height: 43px;
      background-color: #fff1ef;
      color: #d52b1e;
      font-size: 16px;
      font-weight: 500;
      line-height: 19px;
      border: 0;
      border-radius: 8px;
      @include mobile{
        font-size: 14px;
      }
    }
  }
}
