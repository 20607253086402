@import "../../../../../../css/index.scss";
.spin {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  width: 100% !important;
  height: 613px;
  border: 1px;
  border-radius: 8px;
  border-style: solid;
  border-color: $neutral-grey-300;
  @include mobile{
    width: 100% !important;
    height: 90vh !important;
    border: 0;
  }
}
.wrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100% !important;
  height: 613px;
  border: 1px;
  border-radius: 8px;
  border-style: solid;
  border-color: $neutral-grey-300;
  padding: 16px;
  
  .Contacts {
    display: flex;
    flex-direction: column;
    gap: 12px;
    @include mobile{
      width: 100%;
    }
  }
  .contactList {
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    .contactCount {
      font-weight: 400;
      font-size: 18px;
    }
  }
  .profileDetails {
    display: flex;
    flex-direction: row;
    gap: 12px;
    align-items: flex-start;
    width: 100% !important;
    .profileInfo{
      display: flex;
      flex-direction: column;
      gap: 4px;
    }
    .email {
      font-size: 16px;
      font-family: 500;
      line-height: 21px;
      color: #101928;
    }
    .name {
      font-size: 12px;
      font-family: 400;
      line-height: 16px;
      color: #101928;
    }
    .profile {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: $neutral-grey-300;
      width: 32px;
      height: 32px;
      border-radius: 50%;
      h1 {
        font-size: 12px;
        font-weight: 500;
        line-height: 21px;
      }
    }
  }
  .searchInput {
    width: 100% !important;
    height: 45px !important;
    @include mobile{
      width: 100% !important;
    }
  }
  .SelectWrapper {
    border-style: solid;
    border-width: 1px;
    border-color: #dcdee2;
    outline-color: #dcdee2;
    outline-style: solid;
    outline-width: 1px;
  }
  .selectInput {
    width: 106px !important;
    height: 45px !important;
  }
  .params {
    display: flex;
    flex-direction: row;
    width: 100%;
    gap: 16px;
  }
  @include screen-lg {


    width: 100%;
  }
  @include mobile {
    border: 0;
    padding: 0px;
    height: 100%;
    width: 100% !important;
  }
}
