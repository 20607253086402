@import "../../../../css/index.scss";
.modalWrapper {
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: center;
  justify-content: center;
  .loadingText {
    font-size: 16px;
    font-weight: 400;
    color: #19171a;
  }
  .title {
    color: #010516;
    font-size: 18px;
    font-family: 700;
  }
  .description {
    color: #555c74;
    font-size: 16px;
    font-weight: 400;
  }
  .buttons {
    justify-self: flex-end;
    display: flex;
    flex-direction: row;
    gap: 16px;
    align-items: center;
    @include mobile {
      gap: 8px;
    }
    .cancelBtn {
      background-color: $brand-color;
      border-radius: 8px;
      padding: 16px;
      height: 51px;
      width: 100%;
      color: $primary-color;
      font-size: 16px;
      font-weight: 500;
      border: 0;
      &:hover {
        background-color: darken($brand-color, 10%) !important;
      }
      @include mobile {
        height: 40px;
        font-size: 12px;
      }
    }

    .submitBtn {
      font-size: 16px;
      font-weight: 500;
      border: 0;
      background-color: $primary-color-500;
      color: #ffffff;
      width: 100%;

      height: 51px;
      &:hover {
        background-color: darken($primary-color-500, 10%) !important;
      }
      @include mobile {
        height: 40px;
        font-size: 12px;
      }
    }
  }
}
.wrapper {
  display: flex;
  flex-direction: column;
  gap: 35px;
  margin-top: 10px;
  .header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    .buttons {
      justify-self: flex-end;
      display: flex;
      flex-direction: row;
      gap: 16px;
      align-items: center;
      @include mobile{
        display: none;
      }
      .cancelBtn {
        background-color: $brand-color;
        border-radius: 8px;
        padding: 16px;
        height: 51px;

        color: $primary-color;
        font-size: 16px;
        font-weight: 500;
        border: 0;
        @include mobile {
          height: 40px;
          font-size: 12px;
        }
      }

      .submitBtn {
        font-size: 16px;
        font-weight: 500;
        border: 0;
        background-color: $primary-color-500;
        color: #ffffff;
        height: 51px;
        @include mobile {
          height: 40px;
          font-size: 12px;
        }
      }
      .disabledBtn {
        background-color: #dee0ed !important;
        color: #898ea9 !important;
      }
    }
    .msgTitleWrapper {
      width: 50%;
      word-break: break-word; /* Break words only if necessary */
      overflow-wrap: break-word; /* Ensures text wraps when it overflows */
      white-space: normal;
      @include mobile{
        width: 100%;
      }
      h1 {
        font-weight: 700;
        font-size: 24px;
        color: #120d0c;

        @include mobile {
          font-size: 20px;
        }
      }
    }
    .msgTitle {
      font-size: 24px;
      font-weight: 700;
      line-height: 28px;
      color: #120d0c;
    }
  }
  .main {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 80px;
    @include mobile {
      flex-direction: column;
      gap: 24px;
    }
    .sectionOne {
      display: flex;
      flex-direction: column;
      width: 45%;
      gap: 35px;
      .mobileBtn {
        justify-self: flex-end;
        display: none;
        flex-direction: row;
        gap: 16px;
        align-items: center;
        @include mobile{
          display: flex;
          flex-direction: column;
          align-items: normal;
        }
        .cancelBtn {
          background-color: $brand-color;
          border-radius: 8px;
          padding: 16px;
          height: 51px;
  
          color: $primary-color;
          font-size: 16px;
          font-weight: 500;
          border: 0;
          @include mobile {
            height: 40px;
            font-size: 12px;
          }
        }
  
        .submitBtn {
          font-size: 16px;
          font-weight: 500;
          border: 0;
          background-color: $primary-color-500;
          color: #ffffff;
          height: 51px;
          @include mobile {
            height: 40px;
            font-size: 12px;
          }
        }
        .disabledBtn {
          background-color: #dee0ed !important;
          color: #898ea9 !important;
        }
      }
      @include mobile {
        width: 100%;
        gap: 16px;
      }

      .helperText {
        font-size: 12px;
        font-weight: 400;
        color: $neutral-grey-500;
        @include mobile {
          font-size: 12px;
        }
      }
      .switchWrapper {
        display: flex;
        flex-direction: row;
        gap: 4px;
        align-items: center;
        .label {
          font-size: 16px;
          color: #240806;
          font-weight: 500;
          @include mobile {
            font-size: 14px;
          }
        }
      }
      .inputWrapper {
        .label {
          font-size: 16px;
          color: #240806;
          font-weight: 500;
          @include mobile {
            font-size: 14px;
          }
        }
        display: flex;
        flex-direction: column;
        gap: 8px;
        @include mobile {
          width: 100% !important;
        }
        .textArea {
          width: 100% !important;
          height: 120px !important;
          outline-color: $neutral-grey-300 !important;
          border-radius: 4px !important;
          border-width: 1px !important;
          border-color: $neutral-grey-300 !important;
          font-size: 16px;
          font-weight: 400 !important;
          //padding: 12px;
          border-style: solid !important;
          border-radius: 4px !important;
          border-width: 1px !important;
          background-color: none !important;
          @include mobile {
            height: 60px !important;
          }
        }
        .input {
          color: #120d0c;
          width: 100% !important;
          height: 40px !important;
          outline-color: $neutral-grey-300 !important;
          border-radius: 4px !important;
          border-width: 1px !important;
          border-color: $neutral-grey-300 !important;
          font-size: 16px;
          font-weight: 400 !important;
          //padding: 12px;
          border-style: solid !important;
          border-radius: 4px !important;
          border-width: 1px !important;
          background-color: none !important;
          @include mobile {
            width: 100% !important;
            height: 40px !important;
          }
        }
        .errorMessage {
          font-size: 14px;
          font-weight: 400;
          color: $primary-color-500;
        }
        .inputError {
          border-color: $primary-color-500 !important;
          outline-color: $primary-color-500 !important;
        }
      }
    }
    .sectionTwo {
      width: 55%;
      border: 1px solid #dee0ed;
      border-radius: 4px;
      padding: 12px;
      display: flex;
      flex-direction: column;
      gap: 24px;
      @include mobile {
        width: 100%;
      }
    }
  }
}
