@import "../../../../../css/index.scss";
.name {
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  @include desktop {
    @media (max-width: 1024px) {
      font-size: 17px;
    }
  }
}
.modalBody {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  gap: 24px;

  .texts {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    gap: 8px;

    .message {
      font-size: 18px;
      font-weight: 700;
      color: #010516;
      @include mobile {
        font-size: 16px;
      }
    }
    .subMessage {
      font-size: 16px;
      font-weight: 400;
      color: #555c74;
      @include mobile {
        font-size: 12px;
      }
    }
  }
  .modalContactBtns {
    display: flex;
    flex-direction: row;
    @include mobile {
      width: 100%;
      flex-direction: column;
      gap: 12px;
    }
    gap: 24px;
    .button {
      padding: 16px;
      height: 51px !important;
      border: 0;
      border-radius: 8px;
      width: 200px !important;
      font-size: 16px !important;
      font-weight: 500 !important;
      @include mobile {
        width: 100% !important;
        font-size: 14px !important;
        height: 40px !important;
      }
    }
    .cancelBtn {
      background-color: $brand-color;
      color: $primary-color;

      &:hover {
        background-color: $brand-color !important;
        color: $primary-color;

        cursor: pointer;
      }
    }
    .modalContactBtn {
      background-color: $primary-color;
      color: $brand-color !important;

      &:hover {
        background-color: $primary-color-700 !important;
        color: $brand-color !important;

        cursor: pointer;
      }
    }
  }
}
.wrapper {
  @include mobile {
    position: relative;
    //min-height: 100% !important;
  }
  .spin {
    width: 100% !important;
    height: 100% !important;
    display: flex;
    margin: auto;
    justify-content: center;
    align-items: center;
  }
  .mobileBtn {
    display: none;
    @include mobile {
      position: absolute;
      top: 100%;
      display: flex;
      width: 100%;
      .button {
        padding: 16px;
        height: 51px;
        border: 0;
        border-radius: 8px;
        width: 150px !important;
        font-size: 16px;
        font-weight: 500;
        background-color: $primary-color;
        color: #ffffff;
        &:hover {
          background-color: $primary-color-700;
        }
        @include mobile {
          height: 41px !important;
          width: 100% !important;
          font-size: 14px;
        }
      }
    }
  }
  transition-duration: 100ms;
  transition-property: all;
  transition-timing-function: linear;
  .profileInfo {
    font-size: 16px !important;
    font-family: 500 !important;
    color: #101928 !important;
    @include mobile {
      font-size: 14px !important;
    }
  }
  .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    .headerText {
      display: flex;
      flex-direction: column;
      gap: 8px;
      .groupName {
        font-size: 24px;
        font-weight: 700;
        color: #120d0c;

        @include mobile {
          font-size: 20px;
        }
      }
      .number {
        color: #6a6767;
        font-size: 16px;
        font-weight: 400;
        @include mobile {
          font-size: 12px;
        }
      }
    }
    .dextopBtn {
      @include mobile {
        display: none;
      }

      .button {
        gap: 8px;
        padding: 16px 12px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 128px;
        height: 52px;
        border-radius: 8px;
        background-color: $primary-color;
        font-size: 16px;
        font-weight: 500;
        color: white;
        border: 0;
        transition-duration: 1ms;
        transition-timing-function: ease-in;
        &:hover {
          background-color: $primary-color-700 !important;
          color: white !important;
        }
      }
    }
  }
  .form {
    display: flex;
    flex-direction: row;
    gap: 12px;
    @include mobile {
      width: 100% !important;
      align-items: center;
      gap: 16px;
    }
  }
  .footer {
    @include mobile {
      bottom: 0;
      width: 100%;
    }
  }
  .addContactBtns {
    display: flex;
    flex-direction: row;
    gap: 24px;

    @include mobile {
      flex-direction: column-reverse;
      justify-self: flex-end;

      width: 100%;
    }

    .button {
      padding: 16px;
      height: 51px;
      border: 0;
      border-radius: 8px;
      width: 150px !important;
      font-size: 16px;
      font-weight: 500;
      @include mobile {
        height: 41px !important;
        width: 100% !important;
        font-size: 14px;
      }
    }
    .cancelBtn {
      background-color: $brand-color;
      color: $primary-color;

      &:hover {
        background-color: $brand-color !important;
        color: $primary-color;

        cursor: pointer;
      }
    }
    .addContactBtn {
      background-color: $primary-color;
      color: $brand-color !important;

      &:hover {
        background-color: $primary-color-700 !important;
        color: $brand-color !important;

        cursor: pointer;
      }
    }
  }

  .mainWrapper {
    display: grid;
    grid-template-areas: "contacts divider about";
    column-gap: 0;
    align-items: stretch;
    grid-template-columns: 372px 10px auto;
    @include desktop {
      @media (max-width: 1024px) {
        grid-template-columns: 300px 10px auto;
      }
    }
    @include mobile {
      grid-template-columns: 100%;
      align-items: normal;
      grid-template-areas: "contacts";
      justify-content: center;
    }
    .contactList {
      grid-area: contacts;
      width: 371px;
      display: flex;
      flex-direction: column;

      .input {
        height: 45px !important;
        width: 2191px !important;
        border-radius: 8px;
        @include mobile {
          width: 100% !important;
          height: 40px !important;
        }
      }
      @include desktop {
        @media (max-width: 1024px) {
          width: 300px !important;
        }
      }
      @include mobile {
        //position: relative;
        width: 100% !important;
        min-height: 35vh !important;
      }

      gap: 12px;
      .contacts {
        flex-direction: column;
        gap: 12px;
        @include mobile {
          width: 100% !important;
          align-self: center;
          justify-self: center;
        }
        .contact {
          display: flex;
          flex-direction: row;
          gap: 12px;
          align-items: center;
          cursor: pointer;
          padding: 8px 12px 8px 12px;
          @include mobile {
            width: 100% !important;
          }
          &:hover {
            background-color: $brand-color;
          }

          .profile {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            width: 32px;
            height: 32px;
            font-size: 12px;
            font-weight: 500;
            text-align: center;
            background-color: $neutral-grey-200;
            border-radius: 50%;
          }
        }
      }
      .contactsMobile {
        display: none;
        flex-direction: column;
        gap: 12px;
        @include mobile {
          display: flex;
          width: 100% !important;
          align-self: center;
          justify-self: center;
        }
        .contact {
          display: flex;
          flex-direction: row;
          gap: 12px;
          align-items: center;
          cursor: pointer;
          padding: 8px 12px 8px 12px;
          @include mobile {
            width: 100% !important;
          }
          &:hover {
            background-color: $brand-color;
          }
        }
      }
      .contactsDesktop {
        display: flex;
        flex-direction: column;
        gap: 12px;
        @include mobile {
          display: none;
        }
        .contact {
          &:hover {
            background-color: $brand-color;
          }
        }
        .active {
          background-color: $brand-color;
        }
      }
    }
    .divider {
      grid-area: divider;
      min-height: 70vh; // Make sure the divider has height
      width: 1px; // Set a small width for the divider
      background-color: #e0e0e0; // Choose a divider color
      @include mobile {
        display: none;
      }
    }
    .aboutContact {
      grid-area: about;
      width: 400px;
      align-items: center;
      justify-content: center;
      align-self: center;
      margin: 0 auto;
      @include desktop {
        @media (max-width: 1024px) {
          width: 355px;
        }
      }
      @include mobile {
        display: none;
      }
      .selected {
        text-align: center;
        font-size: 16px;
        font-weight: 500;
        color: $neutral-grey-500;
      }
      .contactDetails {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        align-self: center;
        gap: 24px;
        .profileDetails {
          display: flex;
          flex-direction: row;
          width: 355px;
          align-items: center;
          gap: 16px;
          .profilePic {
            width: 80px;
            height: 80px;
            border-radius: 50%;
            background-color: $neutral-grey-200 !important;
            display: flex;
            justify-content: center;
            align-items: center;
            @include desktop {
              @media (max-width: 1024px) {
                width: 70px;
                height: 70px;
              }
            }
            p {
              text-align: center;
              font-size: 20px;
              font-weight: 700;
              line-height: 24px;
              color: #101928;
              @include desktop {
                @media (max-width: 1024px) {
                  font-size: 17px;
                }
              }
            }
          }
        }
      }
      .table {
        min-width: 335px;

        tbody {
          width: 100%;
          display: flex;
          flex-direction: column;
          gap: 8px;
        }
        tr {
          width: 100%;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
        }
        .tableLabel {
          color: $neutral-grey-500;
          font-size: 16px;
          font-weight: 500;
          line-height: 19px;
          @include desktop {
            @media (max-width: 1024px) {
              font-size: 14px;
              line-height: 16px;
            }
          }
        }
        .tableData {
          color: $neutral-black;
          font-size: 14px;
          font-weight: 400;
          @include desktop {
            @media (max-width: 1024px) {
              font-size: 13px;
            }
          }
          .groupNames {
            display: flex;
            flex-direction: row;
            gap: 8px;
            .groupName {
              font-size: 12px;
              background-color: $neutral-grey-200;
              padding: 0.5px 8px 0.5px 8px;
              border-radius: 8px;
            }
          }
        }
      }
    }
  }
}
.modal {
  width: 100% !important;
  .aboutContact {
    grid-area: about;
    width: 100% !important;
    align-items: center;
    justify-content: center;
    align-self: center;
    margin: 0 auto;
    @include mobile {
      display: flex;
      width: 100% !important;
    }
    .selected {
      text-align: center;
      font-size: 16px;
      font-weight: 500;
      color: $neutral-grey-500;
    }
    .contactDetails {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      align-self: center;
      gap: 24px;
      @include mobile {
        width: 100% !important;
      }
      h2 {
        font-size: 18px;
        font-weight: 500;
      }
      .profileDetails {
        display: flex;
        flex-direction: column;
        width: 100%;
        align-items: center;
        gap: 12px;
        .profilePic {
          width: 56px;
          height: 56px;
          border-radius: 50%;
          background-color: $neutral-grey-200 !important;
          display: flex;
          justify-content: center;
          align-items: center;
          p {
            text-align: center;
            font-size: 20px;
            font-weight: 700;
            line-height: 24px;
            color: #101928;
          }
        }
      }
    }
    .table {
      width: 100% !important;
      border-collapse: separate;
      border-spacing: 10px 24px;
      display: flex;
      flex-direction: column;
      gap: 8px;
      .dataWrap {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
      }
      .tableLabel {
        color: $neutral-grey-500;
        font-size: 16px;
        font-weight: 700;
        line-height: 19px;
        @include mobile {
          font-size: 14px;
          line-height: 16px;
        }
      }
      .tableData {
        color: $neutral-black;
        font-size: 14px;
        @include mobile {
          font-size: 14px;
        }
        .groupNames {
          display: flex;
          flex-direction: row;
          gap: 8px;
          .groupName {
            font-size: 12px;
            background-color: $neutral-grey-200;
            padding: 0.5px 8px 0.5px 8px;
            border-radius: 8px;
          }
        }
      }
    }
  }
}
