@import "../../css/index.scss";

.ant-table-wrapper {
  height: fit-content !important;
  overflow-x: auto !important;
  font-family: $font-neue-montreal !important;

  width: 100%;
}

.ant-table-thead {
  font-size: 16px;
  font-family: $font-neue-montreal !important;

  background-color: $neutral-grey-100;
  @include mobile {
    font-size: 14px !important;
  }
}

.ant-table-thead .ant-table-selection-column {
  display: flex;
  justify-content: center !important;
  align-items: center !important;
  font-family: $font-neue-montreal !important;

}

.ant-table-cell {
 
  font-family: $font-neue-montreal !important;

  white-space: nowrap; // Prevent text from wrapping
  overflow: hidden; // Hide overflowed text
  text-overflow: ellipsis; // Add ellipsis (...) to truncated text
  max-width: 200px;

  @include screen-lg {
    max-width: 200px;
  }
  @include mobile {
    max-width: 130px; // Adjust width to visually limit character count
  }
}

.ant-table-cell {
  font-family: $font-neue-montreal !important;

  color: #6b7280 !important;
  font-weight: 12px !important;
  font-weight: 500 !important;
}
