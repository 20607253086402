@import "../../css/index.scss";
.ant-checkbox-wrapper-checked .ant-checkbox-inner {
  background-color: $primary-color; // Change the background color when checked
  border-color: $primary-color; // Change the border color when checked
}

.ant-checkbox-indeterminate .ant-checkbox-inner::after {
  background-color: $primary-color; // Change the indeterminate indicator color
}
.ant-checkbox-group {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100% !important;
}
.ant-checkbox-group-item {
  flex: 1 !important;
  width: 100% !important;
}
.ant-checkbox-wrapper {
  
  display: flex;
  width: 100% !important;
  flex-direction: row-reverse;
  justify-content: flex-end;
  position: relative;
}
.ant-checkbox-label {
  text-align: left !important; // Ensure label aligns on the left
  font-size: 26px !important;
  font-weight: 500 !important;
  color: #101928;
  @include mobile{
    font-size: 14px !important;
  }
}
.ant-checkbox{
  position: absolute;
  right: 0;
}