@import "../../css/index.scss";
.modalBody {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
    gap: 24px;
  
    .texts {
      display: flex;
      flex-direction: column;
      justify-content: center;
      text-align: center;
      gap: 8px;
  
      .message {
        font-size: 18px;
        font-weight: 700;
        color: #010516;
        @include mobile{
          font-size: 16px;
        }
      }
      .subMessage {
        font-size: 16px;
        font-weight: 400;
        color: #555c74;
        @include mobile{
          font-size: 12px;
        }
      }
    }
    .modalContactBtns {
      display: flex;
      flex-direction: row;
      @include mobile {
        width: 100%;
        flex-direction: column;
        gap: 12px;
      }
      gap: 24px;
      .button {
        padding: 16px;
        height: 51px !important;
        border: 0;
        border-radius: 8px;
        width: 200px !important;
        font-size: 16px !important;
        font-weight: 500 !important;
        @include mobile {
          width: 100% !important;
          font-size: 14px !important;
          height: 40px !important;
        }
      }
      .cancelBtn {
        background-color: $brand-color;
        color: $primary-color;
  
        &:hover {
          background-color: $brand-color !important;
          color: $primary-color;
  
          cursor: pointer;
        }
      }
      .modalContactBtn {
        background-color: $primary-color;
        color: $brand-color !important;
  
        &:hover {
          background-color: $primary-color-700 !important;
          color: $brand-color !important;
  
          cursor: pointer;
        }
      }
    }
  }