@import "../../css/index.scss";
.wrapper{
  display: flex;
  flex-direction: column;
  gap: 16px;
  max-height: 380px;
  overflow: auto;
}
.btnGroups{
  display: flex;
  flex-direction: row;
  gap: 8px;
  justify-content: space-between;
  margin-top: 8px;
  .button {
    background-color: $primary-color;
    width: 50%;
    height: 51px !important;
    color: white;
    font-weight: 500;
    font-size: 16px;
    border-radius: 8px;
    line-height: 19px;
    padding: 16px 12px 16px 12px;
    border: 0;
    &:hover {
      color: white !important;
      background-color: $primary-color-700 !important;
    }
    @include mobile {
      width: 100%;
      height: 42px !important;
      font-size: 14px;
      margin-top: 10px;
    }
  }
}
.buttonSample {
  background-color: $primary-color;
  width: 100%;
  height: 41px !important;
  color: white;
  font-weight: 500;
  font-size: 14px;
  border-radius: 8px;
  line-height: 19px;
  padding: 16px 12px 16px 12px;
  border: 0;
  &:hover {
    color: white !important;
    background-color: $primary-color-700 !important;
  }
  @include mobile {
    width: 100%;
    height: 42px;
    font-size: 14px;
    //margin-top: 10px;
  }
}
.button {
  background-color: $primary-color;
  width: 188px;
  height: 51px !important;
  color: white;
  font-weight: 500;
  font-size: 16px;
  border-radius: 8px;
  line-height: 19px;
  padding: 16px 12px 16px 12px;
  border: 0;
  &:hover {
    color: white !important;
    background-color: $primary-color-700 !important;
  }
  @include mobile {
    width: 100%;
    height: 42px;
    font-size: 14px;
    margin-top: 10px;
  }
}
.cell {
  @include mobile {
    font-size: 12px;
    color: $neutral-grey-500;
  }
}
.name {
  color: $neutral-black;
}
