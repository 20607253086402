@import "../../../css//index.scss";
.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: center;
  gap:8px;
  .title {
    font-size: 24px;
    font-weight: 500;
    color: #240806;
    line-height: 28px;
    text-align: center;
    @include mobile{
      line-height: 19px;
      font-size: 18px;
    }
  }
  .subtitle {
    font-size: 16px;
    font-weight: 400;
    color: #6a6767;
    line-height: 28px;
    text-align: center;
    @include mobile{
      font-size: 14px;
      line-height: 19px;
    }
  }
  .icon{
    @include mobile{
      width: 30px !important;
    }
  }
}
