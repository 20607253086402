@import "../../../css/index.scss";
.wrapper {
  width: 20px;
  height: 20px;
  @include desktop {
    @media (max-width: 1024px) {
      width: 17px;
      height: 17px;
    }
  }
  @include mobile {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 13px;
    height: 13px;
  }
}
