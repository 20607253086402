@import "../../../../css/index.scss";
.wrapper {
  padding: 48px 32px 48px 32px !important;

  .BreadCrumb {
    @include mobile {
      display: none;
    }
  }
  @include mobile {
    padding: 0px 20px 0px 20px !important;
  }
  .active {
    background-color: none !important;
    color: $neutral-grey-500;
  }
}
