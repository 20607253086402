@import "../../../../../css/index.scss";
.wrapper {
  height: 100% !important;
  .helpers {
    display: flex;
    flex-direction: column;
    gap: 8px;
    .helperText {
      font-size: 12px;
      font-weight: 400;
      color: $neutral-grey-500;
      @include mobile {
        font-size: 10px;
      }
    }
  }
}
