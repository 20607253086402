@import "../../../../css/index.scss";
.wrapper{
    padding: 48px 32px 48px 32px !important;
    @include mobile{
        padding: 0px 20px 20px 20px !important;
    }
    .active{
        background-color: none !important;
        color: $neutral-grey-500;
    }
    .breadCrumb{
        @include mobile{
            display: none;
        }
    }
}