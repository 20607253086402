@import "../../../../css/index.scss";
.logoutBtn {
  border: 0;
  width: 169px;
  height: 51px;
  font-size: 16px;
  font-weight: 500;
  color: white;
  border-radius: 8px;
  background-color: $primary-color;
}
