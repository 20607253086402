@import "../../../../css/index.scss";
.PopOverItem {
  display: flex;
  align-items: center; 
  gap: 8px;
  padding-left: 16px;
  cursor: pointer; 
  width: 200px;
  height: 53px;
  z-index: -100;
  h5 {
    font-size: 14px;
    font-weight: 400;
    color: #19171a;
    @include mobile {
      font-size: 12px;
    }
  }

  &:hover {
    background-color: rgba(0, 0, 0, 0.1); // Slightly darken background on hover
  }
}
.searchParams {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 12px;
  align-items: center;
  @include desktop {
    @media (max-width: 1024px) {
      width: 100% !important;
      flex-direction: column;
    }
  }

  @include mobile {
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
  }
}

// :global(.ant-popover-content)  {
//   position: absolute !important;
//   left: -700px !important;
//   @include mobile{
//     left: -800px !important;
//    // right:  100% !important; /* Example offset */
//   }
//  }

.table {
  display: flex;
  flex-direction: column;
  gap: 12px;
  border-radius: 8px;
  border-style: solid;
  border-width: 1px;
  border-color: $neutral-grey-200;
}
.SelectWrapper {
  height: 45px !important;
  outline-color: $neutral-grey-200 !important;
  outline-width: 1px !important;
  outline-style: solid !important;
  @include mobile {
    border-radius: 6px;
    height: 32px !important;
    font-size: 12px;
  }
}

.input {
  width: 304px !important;
  height: 45px !important;
}
.filters {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 429px !important;
  gap: 16px;
  @include desktop {
    @media (max-width: 1024px) {
      width: 100% !important;
    }
  }
  @include mobile {
    flex-direction: column;
    gap: 12px;
    width: 100% !important;
    align-items: normal;
  }
}
form {
  .scheduleWrapper {
    .input {
      width: 100% !important;
    }
  }
  .addContactBtns {
    width: 100% !important;
    .button {
      width: 50%;
    }
  }
}
.paginate {
  @include desktop {
    @media (max-width: 1024px) {
      width: 100% !important;
    }
  }
  @include mobile {
    align-self: flex-start;
  }
}
.cell {
  @include mobile {
    font-size: 12px;
    color: $neutral-grey-500;
  }
}
.messageTitle {
  color: $neutral-black;
}
