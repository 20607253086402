@import "../../css/index.scss";

.ant-drawer-header {
  background-color: $brand-color; // Using your defined brand color
  height: 105px !important;
  padding: 32px 24px 32px 24px !important;
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
  justify-content: center !important;
}

// Styling for the drawer container
.ant-drawer-wrapper {
  overflow: hidden !important;
  width: fit-content;
  @include mobile {
    display: none !important;
  }
}

// Styling for the drawer title
.ant-drawer-title {
  line-height: 28px !important; // Set line height for the title
  font-weight: 700 !important; // Set font weight to bold
  font-size: 24px !important; // Set font size to 24px
  color: #0d0f11; // Set the text color to a dark shade
}

// Custom styling for the close icon (based on previous requirement)
.ant-drawer-close {
  position: absolute;
  right: 16px;
  background-color: white !important; // Background for the close icon
  border-radius: 50% !important; // Circular background
  padding: 4px !important;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1); // Optional shadow
}

.ant-drawer-close-icon {
  color: #000; // Icon color
  font-size: 16px; // Icon size
}
.ant-drawer {
  overflow: hidden !important;

  @include mobile {
    display: none !important;
  }
}
.ant-drawer-content-wrapper {
  overflow: hidden !important;
  width: fit-content;
  @include mobile {
    display: none !important;
  }
}
.ant-drawer-content {
  overflow: hidden !important;
  width: fit-content;

  @include mobile {
    display: none !important;
  }
}
