@import "../../../../css/index.scss";

.wrapper {
  padding: 48px 32px 48px 32px !important;
  @include mobile {
    padding: 0px 20px 0px 20px !important;
  }
  .breadCrumb {
    .homeLink {
      display: flex;
      flex-direction: row;
      gap: 4px;
      align-items: center;
    }
    @include mobile {
      display: none;
    }
  }
  .active {
    background-color: none !important;
    color: $neutral-grey-500;
  }
}
