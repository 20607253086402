@import "../../../../../../css/index.scss";

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding: 0px 16px 0px 16px;
  .title {
    color: #120d0c;
    font-size: 18px;
    font-weight: 400;
    @include mobile {
      font-size: 16px;
    }
  }
  @include mobile {
    gap: 16px;
  }
  .active {
    width: 50px;
    color: #19d84e;
    background-color: #19d84e1a;
    padding: 2px 8px 2px 8px;

  }
  .inactive {
    width: 50px;
    color: $primary-color-500;
    background-color: #fff1ef;
    padding: 2px 8px 2px 8px;
  }
}
