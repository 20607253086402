@import "../../css/index.scss";
.spin {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100% !important;
  height: 100% !important;
  @include mobile{
    height: 430px !important;
  }
}
.name {
  font-size: 18px;
  font-weight: 500;
  @include mobile{
    font-size: 16px;
  }
}
.tags {
  border-style: solid;
  border-width: 1px;
  border-radius: 4px;
  border-color: #dcdee2;
  display: flex;
  flex-direction: row;
  align-items: center; /* Aligns items vertically */
  justify-content: flex-start; /* Aligns items horizontally, change to space-between if needed */
  padding: 20px 12px 10px 12px;
  flex-wrap: wrap; /* Allows items to wrap to the next line */
  min-height: 64px;
  row-gap: 12px; /* Space between rows */
  column-gap: 12px; /* Space between columns */
  & > :nth-child(5) {
    margin-top: 4px !important;
  }
}
.wrapper {
  width: 100% !important;
  display: flex;
  flex-direction: column;
  gap: 5px;
  height: 100% !important;
  position: relative;
  gap: 24px;
  .checkbox {
    width: 100% !important;
  }
  .addContactBtns {
    position: absolute;
    bottom: 0;
    display: flex;
    flex-direction: row;
    gap: 24px;
    @include mobile {
      position: relative;

      flex-direction: column;
      width: 100%;
    }
  }
  .cancelBtn {
    background-color: $brand-color;
    color: $primary-color;
    &:hover {
      background-color: $brand-color !important;
      opacity: 90%;
      color: $primary-color;

      cursor: pointer;
    }
  }
  .addContactBtn {
    background-color: $primary-color;
    color: $brand-color !important;
    &:hover {
      background-color: $primary-color-700 !important;
      color: $brand-color !important;

      cursor: pointer;
    }
  }
  .button {
    padding: 16px;
    height: 51px;
    border: 0;
    border-radius: 8px;
    width: 198px;
    font-size: 16px;
    font-weight: 500;
    @include mobile {
      width: 100%;
      height: 40px;
      font-size: 14px;
    }
  }
}
