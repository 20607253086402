@import "../../../../css/index.scss";
.headerIcons {
  display: none;

  @include mobile {
    display: flex;
    flex-direction: row;
    gap: 24px;
    align-items: center;
  }
}
.wrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding: 16px 32px;
  border-bottom: 1px solid;
  align-items: center;
  border-color: $neutral-grey-300;
  height: 72px;
  
  @include mobile {
    position: fixed;
    background-color: white;
    z-index: 10;
    top: 0;
    width: 100vw;
    justify-content: space-between;
    padding-left: 20px;
    padding-right: 20px;
  }
}

.headerTitle {
  display: none;
  .name {
    font-size: 16px;
    font-weight: 700;
  }
  @include mobile {
    display: flex;
  }
}
.profilePics {
  @include mobile {
    display: none;
  }
}
.profileInfo {
  display: flex;
  align-items: center;
  gap: 20px;
  width: 271px;
  height: 40px;
  :global(.ant-popover-inner) {
    padding: 0px !important;
  }
  @include mobile {
    width: 100% !important;
    justify-content: space-between;
  }
  .notificationIcon {
  }
  .profileDetails {
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: #101928;
    @include mobile {
      display: none;
    }
    .profileName {
      font-size: 14px;
      font-weight: 500; /* Use font-weight instead of font-family */
      line-height: 18.2px;
    }

    .profileEmail {
      font-size: 12px;
      font-weight: 400; /* Use font-weight instead of font-family */
      line-height: 15.6px;
    }
  }
  .arrowDown {
    @include mobile {
      display: none;
    }
  }
  .imageContainer {
    border-radius: 50%;
    width: 40px;
    height: 40px;

    overflow: hidden; /* Hides parts of the image outside the container */
    position: relative;
    @include mobile {
      display: none;
    }
    .profilePics {
      width: 100%;
      position: absolute;
    }
  }
}
