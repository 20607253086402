@import "../../css/index.scss";
.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  .iconWrapper {
    @include mobile {
      width: 50px;
      height: 50px;
      height: 100%;
    }
  }
}
