@import "../../css/index.scss";

.ant-tabs-tab {
  .ant-tabs-tab-btn {
    color: $neutral-grey-400 !important; // Default tab color
    h2 {
      color: $neutral-grey-400 !important; // Default tab color
      font-size: 14px !important;
      font-weight: 500 !important;
      background-color: transparent;
    }
  }
  .ant-badge {
    color: $primary-color !important; // Default tab color

    padding: 4px 8px;
    border-radius: 10px;
    display: inline-block;
    line-height: 1;
    color: $neutral-grey-400 !important;

    h2 {
      color: $neutral-grey-400 !important; // Default tab color
      font-size: 14px !important;
      font-weight: 500 !important;
      background-color: transparent;
    }
  }
}

.ant-tabs-tab-active {
  .ant-tabs-tab-btn {
    color: $primary-color !important; // Active tab color
    h2 {
      color: $primary-color !important; // Default tab color
    }
  }
  .ant-badge {
    color: $brand-color;

    padding: 4px 8px; // Adjust padding for the badge
    border-radius: 10px; // Optional: make the badge round
    display: inline-block; // Ensure it behaves as a block element
    font-weight: bold; // Make text bold if needed
    line-height: 1; // Prevents the badge height from affecting text layout
    h2 {
      color: $primary-color !important; // Default tab color
    }
  }
}

.ant-tabs-ink-bar {
  background-color: $primary-color !important; // Active ink bar color
}
