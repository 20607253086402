@import "../../../css/index.scss";

.wrapper {
  position: fixed;
  top: 0;
  z-index: 90000;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  align-items: center;
  padding: 0px 80px 0px 80px;
  background-color: currentColor;

  width: 100% !important;
  backdrop-filter: blur(2px);
  height: 70px;
  // @include screen-lg {
  //   height: fit-content;
  // }
  // @include desktop {
  //   @media (max-width: 1024px) {
  //     //width: 909px;
  //     height: fit-content;
  //   }
  // }
  @include mobile {
    z-index: 90;
    box-shadow: none;
    height: 52px;
    padding: 20px;
    flex-direction: column;
    align-items: flex-start;
    justify-content: normal !important;
    overflow: hidden;
    gap: 70px;
  }

  .links {
    display: flex;
    gap: 32px;
    height: 22px;
    @include mobile{
      flex-direction: column;
      text-align: center;
      align-self: center;

      gap: 24px;
    }
    a {
      position: relative;
      text-decoration: none;
      color: #ffffff;
      font-size: 18px;
      font-weight: 500;
      line-height: 21px;
      
    }

    a::after {
      content: "";
      z-index: 100;
     
      width: 0%;
      height: 4px; /* Thickness of the underline */
      position: absolute;
      bottom: -10px; /* Distance from the text */
      left: 0;
      transition: all 0.3s ease, left 0.3s ease; /* Transition for underline */
    }

    /* Hover or active states */
    a:hover::after {
      background-color: #ffffff; /* Underline color */
      width: 100%;
      left: 0;
    }
  }
  .logo {
    cursor: pointer;
    width: 120px;
    height: 40px;
    object-fit: contain;
    @include desktop {
      @media (max-width: 1024px) {
        width: 85.2px;
        height: 28.4px;
      }
    }
    @include mobile {
      width: 80px;
      height: 26px;
    }
  }

  .navigations {
    display: flex;
    flex-direction: row;
    gap: 16px;
    @include mobile{
      flex-direction: column;
      width: 100%;
      gap: 24px;
    }
    button {
      border-radius: 8px;
      border: 0;
      height: 44px !important;
      font-weight: 500;
      font-size: 16px;
      @include mobile{
        width: 100%;
      }
    }

    .signinBtn {
      width: 83px;
     
      background-color: transparent !important;
      color: #ffffff;
      height: 44px !important;
      &:hover{
        color: $brand-color;
      }
      @include mobile{
        width: 100%;
      }
    }

    .registerBtn {
      width: 167px;
      background-color: $primary-color-500;
      color: #ffffff;
      @include mobile{
        width: 100%;
      }
    }
  }
  .hamburger {
    display: none;
    @include mobile {
      position: absolute;
      right:35px;
      top: 30px;
      display: block;
    }
    .content::before {
      position: absolute;
      content: "";
      width: 100%;
      height: 3px;
      background-color: #ffffff;
      top: -8px;
      transform-origin: center;
    }
    .content::after,
    .content::before {
      transition: transform 0.3s ease, visibility 0.3s ease, top 0.3s ease,
        bottom 0.3s ease;
    }
    .content.active::before {
      width: 30px;

      transform: rotate(45deg);
      top: -2px;
    }
    .content.active {
      width: 0;
    }
    .content {
      position: relative;
      width: 30px;
      height: 3px;
      background-color: #ffffff;
      cursor: pointer;
      transition: width 1s ease;
      align-self: center;
    }
    .content::after {
      position: absolute;
      content: "";
      width: 100%;
      height: 3px;
      background-color: #ffffff;
      top: 8px;
      transform-origin: center;
    }
    .content.active::after {
      width: 30px;

      transform: rotate(-45deg);
      top: 1px;
    }
  }
}
