@import "../../css/index.scss";
.wrapper {
  background-color: $brand-color;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 18px;
  padding-bottom: 16px;
  @include mobile {
    gap: 12px;
  }
  .header {
    width: 100%;
    background-color: $primary-color;
    height: 96px;
    display: flex;
    justify-content: center;
    align-items: center;
    .logo {
      object-fit: contain;
      width: 144px;
      height: 50%;
      @include mobile {
        //width: 72px;
        object-fit: contain;
      }
    }
  }
  .body {
    box-shadow: -2px 2px 16px 0px rgba(0, 0, 0, 0.05);

    background-color: #ffffff;
    width: 70%;
    padding: 32px 48px 32px 48px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    gap: 24px;
    @include mobile {
      width: 95%;
      padding: 12px;
      gap: 12px;
    }
    .main {
      display: flex;
      flex-direction: column;
      gap: 12px;
      align-items: flex-start;
      border-bottom: 1px solid $neutral-grey-200;
      .successMsg {
        font-size: 2em;
        font-weight: 700;
        line-height: 38px;
        color: #120d0c;
        @include mobile {
          font-size: 1.2em;
        }
      }
      .bodyMsg {
        color: #120d0c;
        font-size: 1.25em;
        font-weight: 400;
        line-height: 32px;
        @include mobile {
          font-size: 0.875em;
        }
      }
    }
    .inputs {
        width: 100%;
      display: flex;
      flex-direction: column;
      gap: 24px;
      //width: 584px !important;
      .subtitle {
        font-size: 0.875em;
        color: #120d0c;
        font-weight: 500;
      }
      .submitBtn {
        background-color: #d52b1e;
        width: 148px;
        height: 42px;
        font-size: 0.85em;
        color: #ffffff;
        border-radius: 8px;
        border: 0;
        @include mobile {
          font-size: 0.75em;
        }
      }
      input[type="checkbox"] {
        display: flex;
        justify-content: center;
        align-items: center;
        appearance: none;
        width: 20px;
        height: 20px;
        border: 2px solid #cccdd0;

        position: relative;
        cursor: pointer;
      }
      input[type="checkbox"]:checked {
        border-radius: 4px;
      }
      input[type="checkbox"]:checked {
        border: 2px solid $primary-color;

        background-color: $primary-color;
      }
      input[type="checkbox"]:checked::before {
        content: "✔";
        align-self: center;
        justify-self: center;
        position: absolute;

        color: white;
        font-size: 16px;
        @include mobile{
            font-size: 14px;
        }
      }

      .input {
        display: inline-flex;
        flex-direction: row;
        gap: 12px;
        align-items: flex-start;
        @include mobile {
          gap: 8px;
        }
      }
      label {
        //font-size: 16px;
        font-weight: 400;
        font-size: 1em;
        font-weight: 400;
        color: #6a6767;
        @include mobile {
          font-size: 0.875em;
        }
      }
    }
  }
  .footer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 12px;
    align-items: center;
    @include mobile {
      gap: 8px;
    }
    .reSubscribe {
      font-size: 1em;
      font-weight: 500;
      @include mobile {
        font-size: 0.875em;
      }
    }
    .button {
      background-color: #d52b1e;
      width: 94px;
      height: 30px;
      font-size: 0.75em;
      color: #ffffff;
      border-radius: 8px;
      border: 0;
      &:hover {
        color: #ffffff;
        background-color: #8e1d14;
      }
    }
  }
}
