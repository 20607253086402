@import "../../../css/index.scss";
.mainWrapper {
 
  background-image: linear-gradient(to bottom, #19082a, #0e0d0d);
}
.wrapper {
  padding-top: 100px !important;
  display: flex;
  flex-direction: row;
  width: 100% !important;
  padding: 80px;
  column-gap: 132px;
  flex: 1;
  justify-content: space-between;
  @include desktop {
    @media (max-width: 1024px) {
      column-gap: 66px;
      padding: 60px;
    }
  }
  @include mobile {
    padding: 20px;
    padding-top: 65px !important;

    flex-direction: column;
    column-gap: 0;
    row-gap: 24px;
  }
  .sectionOne {
    display: flex;
    flex-direction: column;
    gap: 32px;
    flex: 0.3;

    @include desktop {
      @media (max-width: 1024px) {
        flex: 0.4;
      }
    }
    @include mobile {
      flex: 1;
      width: 100% !important;
    }
    .title {
      font-size: 40px;
      font-weight: 700;
      color: #ffffff;
      line-height: 48px;
      @include desktop {
        @media (max-width: 1024px) {
          font-size: 30px;
        }
      }
      @include mobile {
        font-size: 18px;
        line-height: 21px;
      }
    }
    .navigations {
      @include mobile {
        overflow-x: auto; /* Allow horizontal scrolling on mobile */
        -webkit-overflow-scrolling: touch;
      }
      p {
        color: #ffffff;
        font-size: 12px;
        font-weight: 400;
        @include mobile {
          display: none;
        }
      }
      ul {
        list-style: none;
        display: flex;
        flex-direction: column;
        gap: 14px;
        width: max-content; /* Ensure that the width adjusts to content */
        white-space: nowrap; /* Prevent word breaking */
        margin-left: 20px;
        margin-top: 20px;
        @include mobile {
          padding-bottom: 10px;
          overflow-x: auto; /* Horizontal scroll if content exceeds container */

          flex-direction: row;
          width: 100% !important;
          margin: 0 !important;
        }
        li {
          height: 27px;
          font-size: 18px;
          font-weight: 400;
          line-height: 27px;
          word-break: normal; /* Prevent word breaking */
          color: #ffffff;
          display: inline-flex; /* Keep items in one row */
          display: flex;
          flex-direction: row;
          gap: 8px;
          align-items: center;
          cursor: pointer;
          transition: all 1.5s ease-in; // Only target animatable property
          @include mobile {
            width: fit-content;
            font-size: 14px;
            line-height: 20px;
            gap: 4px;
            height: 17px;
          }
          img {
            width: 22px;
            height: 22px;
            display: none;
            transition: all 0.5s ease-in;
            @include mobile {
              width: 16px;
              height: 16px;
            }
          }
          &:hover {
            transition: all 1.5s ease-in;

            text-decoration: underline;
            font-weight: 700;
            img {
              transition: all 1.5s ease-in;

              display: inline;
            }
          }
          &.active {
            transition: all 0.5s ease;
            img {
              display: inline;
            }
            text-decoration: underline;
            font-weight: 700;
          }
        }
      }
    }
  }
  .sectionTwo {
    background-color: #ffffff;
    border-radius: 20px;
    padding: 32px;
    flex: 0.6;
    @include mobile {
      padding: 16px;
      flex: 1;
      width: 100% !important;
      margin-bottom: 20px;
    }
    .frequentlyAskedQuestions {
      width: 100%;
      font-family: $font-neue-montreal !important;
      @include desktop {
        @media (max-width: 900px) {
          width: 100% !important;
        }
      }
      @include mobile {
        width: 100% !important;
      }
      @include mobile {
        @media (max-width: 425px) {
          width: 100% !important;
        }
      }

      .answer {
        font-weight: 500;
        font-size: 18px;
        line-height: 27px;
        color: #120d0c;
        @include mobile {
          font-size: 14px;
          line-height: 18px;
        }
      }
      :global(.ant-collapse-header) {
        font-size: 20px !important;
        font-weight: 700;
        line-height: 24px !important;
        color: #120d0c !important;
        display: flex;
        align-items: center;
        height: fit-content;
        @include mobile {
          font-size: 14px !important;
        }
      }
      :global(.ant-collapse-header-text) {
        padding: 16px 0 auto 0 !important;
        font-size: 20px !important;
        font-weight: 700 !important;
        line-height: 27px !important;
        color: #120d0c !important;
        @include mobile {
          font-size: 14px !important;
        }
      }
      :global(.ant-collapse-item) {
        width: 100% !important;
        border: 0 !important;
        border-bottom: 1px solid #dad6d5 !important; /* Customize color as needed */
      }

      :global(.ant-collapse-borderless) {
        width: 100% !important;
        @include desktop {
          @media (max-width: 900px) {
            width: 90% !important;
          }
        }
        @include mobile {
          width: 420px !important;
        }
        @include mobile {
          @media (max-width: 425px) {
            width: 335px !important;
          }
        }
      }
    }
  }
}
