@import "../../css/index.scss";
.phoneInputContainer {
  position: relative;
  width: 100% !important;
  height: 40px !important;
}

.phoneInputDropdown {
  position: absolute;
  bottom: 100%;
  left: 0;
  width: 40%;
  z-index: 9999;
}

.PhoneInput {
  padding-left: 52px !important;
  
}
.wrapper {
  position: relative;

  .optional {
    color: $neutral-grey-300 !important;
  }
  .forms {
    display: flex;
    flex-direction: column;
    padding-bottom: 40px;
    gap: 24px;
    @include mobile{
      gap: 12px;
    }
  }
  form {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    @include mobile{
      justify-content: center;
      gap: 24px;
    }
    .inputWrapper {
      display: flex;
      flex-direction: column;
      gap: 8px;
    }
    .input {
      flex: 1 !important;
      width: 100% !important;
      height: 40px !important;
      outline-color: $neutral-grey-300;
      border-radius: 4px !important;
      border-width: 1px;
      border-color: $neutral-grey-300 !important;
      font-size: 16px;
      font-weight: 400;
      padding: 12px;
      border-style: solid;
      border-radius: 4px;
      border-width: 1px !important;
      @include mobile {
        width: 100% !important;
        font-size: 14px !important;
      }
    }

    .inputError {
      border-color: $primary-color-500 !important;
      outline-color: $primary-color-500 !important;
    }
    .inputLabel {
      font-size: 16px;
      font-weight: 500;
      @include mobile{
        font-size: 14px;
      }
    }
    .passwordIcon {
      position: absolute;
      left: 1120px;
      margin-top: 40px;
    }
    .errorMessage {
      font-size: 14px;
      font-weight: 400;
      color: $primary-color-500;
    }
    .inputPasswordError {
      border-color: $primary-color-500 !important;
      outline-color: $primary-color-500 !important;
    }
    .inputfirstnameError {
      border-color: $primary-color-500 !important;
      outline-color: $primary-color-500 !important;
    }
    .inputlastnameError {
      border-color: $primary-color-500 !important;
      outline-color: $primary-color-500 !important;
    }
    
    .addContactBtns {
      top: 90%;
      display: flex;
      flex-direction: row;
      width: 100% !important;
      gap: 24px;
      @include mobile{
        position: relative;
        flex-direction: column;
        width: 100% !important;
        gap: 12px;
      }
    }
    .cancelBtn {
      background-color: $brand-color;
      color: $primary-color;
      width: fit-content !important;
      &:hover {
        background-color: $brand-color !important;
        opacity: 90%;
        color: $primary-color;

        cursor: pointer;
      }
    }
    .addContactBtn {
      background-color: $primary-color;
      color: $brand-color !important;
      width: fit-content !important;
      &:hover {
        background-color: $primary-color !important;
        color: $brand-color !important;

        opacity: 90%;

        cursor: pointer;
      }
    }
    .button {
      padding: 16px;
      height: 51px;
      border: 0;
      border-radius: 8px;
      width: 50% !important;
      font-size: 16px;
      font-weight: 500;
      @include mobile{
        font-size: 14px;
        width: 100% !important;
        height: 42px !important;
      }
    }
    
    
  }
}
