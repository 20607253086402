@import "../../../../css/index.scss";
.wrapper {
  padding: 12px 0 12px 0;
  display: flex;
  flex-direction: column;
  gap: 24px;
  transition: 10ms;
  transition-property: all;
  width: 100% !important;
  @include desktop {
    @media (max-width: 1024px) {
      padding: 12px 0 12px 0;
    }
  }

  @include mobile {
    padding: 0;
  }
  .mainWrapper {
    padding: 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    grid-template-areas: "sectionOne divider sectionTwo";
    grid-template-columns: 50% 2% auto !important;
    column-gap: 2% !important;
    width: 100% !important;
    @include desktop {
      @media (max-width: 1024px) {
        grid-template-columns: 40% 2% auto;
        column-gap: 2% !important;
      }
    }
    @include mobile {
      justify-content: center;
      align-items: center;
      flex-direction: column;
      grid-template-areas:
        "sectionOne"
        "sectionTwo";
      grid-template-columns: 100% 100%;
      row-gap: 20px;
    }
  }
  .sectionOne {
    flex: 0.5;
    grid-area: sectionOne;
    @include desktop {
      @media (max-width: 1024px) {
        flex: 0.5;
      }
    }
    @include mobile {
      flex: 1;
      width: 100% !important;
    }
  }

  .form {
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: 100% !important;
    padding: 0;
  }
  .divider {
    grid-area: divider;
    min-height: 50vh !important;
    max-height: auto;
    @include desktop {
      @media (max-width: 1024px) {
      }
    }

    @include mobile {
      display: none;
    }
  }
  .sectionTwo {
    flex: 0.43;
    grid-area: sectionTwo;
    @include desktop {
      @media (max-width: 1024px) {
        flex: 0.55;
        //width:max-content !important;
      }
    }
    @include mobile {
      flex: 1;
      min-height: 127px;
      width: 100% !important;
    }
  }
  .tableTitle {
    font-size: 18px;
    font-weight: 700;
    color: #120d0c;
    line-height: 21px;
    @include mobile {
      font-size: 16px;
    }
  }
  .creditQuantity {
    font-size: 14px;
    font-weight: 500;
    color: #101928;
  }
  .sectionTwo,
  .sectionOne {
    display: flex;
    flex-direction: column;
    gap: 32px;
  }
  .sectionOne{
    gap: 24px;
    @include mobile {
      gap: 18px;
    }
  }
  .quantity {
    font-size: 12px;
    font-weight: 500;
    color: $neutral-grey-500;
  }
  .credits {
    font-size: 16px;
    font-weight: 500;
    line-height: 19px;
    color: $neutral-black;
    @include mobile {
      font-size: 14px;
    }
  }
  .inputWrapper {
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 100%;
    @include mobile {
      width: 100%;
    }
  }
  .price {
    font-size: 16px;
    font-weight: 700;
    color: #120d0c;
    line-height: 19px;
    @include mobile {
      font-size: 14px;
    }
  }
  .titles {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
  .title {
    font-size: 24px;
    font-weight: 700;
    color: #120d0c;
    line-height: 28px;
    @include mobile {
      font-size: 20px;
      line-height: 24px;
    }
  }
  .subtitle {
    font-size: 16px;
    font-weight: 400;
    color: #120d0c;
    line-height: 20px;
    @include mobile {
      font-size: 14px;
      line-height: 18.2px;
    }
  }
  .Alert {
    width: 100% !important;
    @include desktop {
      @media (max-width: 1024px) {
        width: 100% !important;
      }
    }
    @include mobile {
      width: 100% !important;
    }
  }
  .inputError {
    border-color: $primary-color-500 !important;
    outline-color: $primary-color-500 !important;
  }
  .errorMessage {
    font-size: 12px;
    font-weight: 400;
    color: $primary-color-500;
  }
  .inputCredit {
    width: 100%;
    height: 40px;

    padding: 12px;
    outline-color: #dcdee2;
    border-color: #dcdee2;
    border-style: solid;
    border-radius: 4px;
    border-width: 1px !important;
    @include desktop {
      @media (max-width: 1024px) {
        width: 100%;
      }
    }
    @include mobile {
      width: 100% !important;
      font-size: 14px;
    }
  }

  .table {
    width: 100%;
    @include desktop {
      @media (max-width: 1024px) {
        width: 100% !important;
      }
    }
    @include mobile {
      width: 100%;
    }
    hr {
      width: 100% !important;
      @include desktop {
        @media (max-width: 1024px) {
          width: 100% !important;
        }
      }
    }
  }

  .leftAlign {
    text-align: left; /* Ensures text is aligned left */
  }

  /* Optional: Flexbox for better control over inner elements */
  .priceDetails {
    display: flex;
    flex-direction: column; /* Stack h5 and p vertically */
    align-items: flex-end; /* Aligns content to the left */
  }
  .button {
    width: 100%;
    height: 43px;
    border-radius: 8px;
    background-color: $primary-color;
    font-size: 16px;
    font-weight: 500;
    color: white;
    border: 0;
    @include desktop {
      @media (max-width: 1024px) {
        width: 100%;
      }
    }
    @include mobile {
      width: 100%;
      font-size: 14px;
    }
    &:hover {
      background-color: $primary-color-700 !important;
      color: white !important;
    }
  }
}
.spin {
  display: flex;
  width: 100%;
  height: 100%;
  margin: auto;
  justify-content: center;
  align-items: center;
}
.helperText {
  font-size: 14px;
  font-weight: 400;
  color: $neutral-grey-500;
  @include mobile {
    font-size: 12px;
  }
}
