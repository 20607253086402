@import "../../../css/index.scss";

.wrapper {
  width: 18px;
  height: 18px;
  @include mobile {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 16px;
      height:16px;
    
  }
}
