@import "../../../../../../../css/index.scss";
.wrapper {
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  border-style: solid;
  border-width: 1px;
  border-color: $neutral-grey-300;
  padding: 24px 16px 24px 16px;
  gap: 12px;
  width: 273px;
  height: 157px;
  text-align: left;
  @include mobile{
    width: 100% !important;
    height: 60px;
    padding: 12px;
    flex-direction: row;
    align-items: center;
  }
  .icon {
    border-radius: 50%;
    background-color: $brand-color;
    width: 50.67px;
    height: 50.67px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 9.33px;
    @include mobile{
      width: 34px;
      height: 34px;
    }
  }
  .title {
    font-size: 18px !important;
    font-weight: 500;
    @include mobile{
      font-size: 14px !important;
    }
  }
  .subtitle {
    font-size: 14px !important;
    font-weight: 400;
    @include mobile{
      font-size: 12px !important;
    }
  }
}
