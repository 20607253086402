@import "../../../../css/index.scss";
.wrapper {
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-top: 24px;
  font-family: $font-neue-montreal;

  @include mobile {
    max-width: 100% !important;
    width: 100% !important;
    margin-top: 0;
  }
  .titles {
    display: flex;
    flex-direction: column;
    gap: 8px;
    .subtitle {
      font-size: 16px;
      font-weight: 400;
      color: #6a6767;
      line-height: 20px;
      @include mobile {
        font-size: 12px;
        line-height: 15px;
      }
    }
    .title {
      font-size: 24px;
      font-weight: 700;
      color: #120d0c;
      line-height: 28px;
      @include mobile {
        line-height: 24px;
        font-size: 20px;
      }
    }
  }
  img {
    width: 100%;
    object-fit: contain !important;
    object-position: center !important;
    min-height: 250px !important;
  }
  .welcomeTab {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  .msgTitleWrapper {
    background-color: $brand-color;
    width: 100%;
    height: 58px;
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
    padding: 18px 24px 18px 24px;

    @include mobile {
      padding: 8px 11px 8px 11px;
      height: 34px;
    }

    h3 {
      white-space: nowrap; // Prevent text from wrapping
      overflow: hidden; // Hide overflowed text
      text-overflow: ellipsis;
      color: #120d0c;
      font-size: 18px;
      font-weight: 700;
      line-height: 21px;
      @include mobile {
        font-size: 16px;
        line-height: 19px;
      }
    }
  }
  .contactBtnGroups {
    display: flex;
    gap: 8px;
    .editBtn {
      background-color: $brand-color;
      color: $primary-color;
      padding: 16px 12px;
      min-width: 93px;
      cursor: pointer;
      height: 50px;
      font-size: 16px;
      font-weight: 500;
      line-height: 19px;
      border-radius: 8px;
      border: 0;
      height: 51px;
      &:hover {
        color: $brand-color !important;
        background-color: $primary-color-700 !important;
        opacity: 90%;
        cursor: pointer;
      }
    }
    @include mobile {
      display: none;
    }
  }
  .contactBtnGroupsMobile {
    display: none;
    @include mobile {
      display: flex;
      flex-direction: column;
      gap: 4px;
      width: 100%;
      .editBtn {
        background-color: $brand-color;
        color: $primary-color;
        padding: 16px 12px;
        width: 100%;
        cursor: pointer;
        height: 50px;
        font-size: 16px;
        font-weight: 500;
        line-height: 19px;
        border-radius: 8px;
        border: 0;
        height: 51px;
        &:hover {
          color: $brand-color !important;
          background-color: $primary-color-700 !important;
          opacity: 90%;
          cursor: pointer;
        }
      }
      .createBtn {
        width: 100%;
      }
    }
  }
  table {
    min-width: 456px;
    height: 97px !important;
    border-spacing: 10px 10px;
    white-space: nowrap !important; // Prevent text from wrapping
    overflow: hidden !important; // Hide overflowed text
    text-overflow: ellipsis !important;
    tr {
      width: 100%;
      white-space: nowrap !important; // Prevent text from wrapping
      overflow: hidden !important; // Hide overflowed text
      text-overflow: ellipsis !important;
      display: flex;
      align-items: flex-start;
      margin-bottom: 20px !important;
      @include mobile {
        margin-bottom: 10px !important;
      }
    }
    @include mobile {
      border-spacing: 5px 0px;
      min-width: 100% !important;
    }
    .emailTitles {
      font-weight: 500;
      font-size: 16px;
      line-height: 21px;
      color: $neutral-grey-500;
      width: 160px !important;

      @include mobile {
        width: 140px !important;
        font-size: 14px;
        line-height: 16px;
      }
    }
    .subtitles {
      font-weight: 500;
      font-size: 16px;
      line-height: 21px;
      color: $neutral-black;
      white-space: nowrap !important; // Prevent text from wrapping
      overflow: hidden !important; // Hide overflowed text
      text-overflow: ellipsis !important;
      word-wrap: break-word;
      @include mobile {
        font-size: 14px;
        line-height: 16px;
      }
      p {
        white-space: wrap !important; // Prevent text from wrapping
        overflow: hidden !important; // Hide overflowed text
        text-overflow: ellipsis !important;
        @include mobile {
          font-size: 14px;
          line-height: 16px;
        }
      }
    }
  }
  .msg {
    border: 1px;
    border-style: solid;
    border-color: $neutral-grey-300;
    max-width: 95% !important;
    width: 793px !important;

    @include mobile {
      max-width: 100% !important;
      width: 100% !important;
    }
  }

  .msgTitle,
  .msgBody {
    padding: 32px 28px 32px 28px;
    @include mobile {
      padding: 16px 12px 24px 12px;
    }
  }
  .msgBody {
    max-height: 450px !important;
    min-height: fit-content !important;
    overflow: auto !important; /* Shows scrollbar only if needed */
    // aspect-ratio: 16/9 !important;
    a {
      text-decoration: none;
      color: #2b56ed;
    }
    img {
      width: 75% !important;
      height: auto;
    }
    p {
      color: #240806;
      font-family: $font-neue-montreal;
      font-weight: 400;
      font-size: 16px;
      @include mobile {
        font-size: 12px !important;
      }
    }
  }
  .msgBodyWrapper {
    background-color: $neutral-grey-300;
    width: 100%;
    height: 58px;

    padding: 18px 24px 18px 24px;
    h3 {
      color: #120d0c;
      font-size: 16px;
      font-weight: 500;
      @include mobile {
        font-size: 14px;
      }
    }
    @include mobile {
      padding: 12px;
    }
  }
  .createBtn {
    background-color: $primary-color;
    color: $brand-color;
    padding: 16px 12px;
    min-width: 93px;
    cursor: pointer;
    height: 50px;
    font-size: 16px;
    font-weight: 500;
    line-height: 19px;
    border-radius: 8px;
    border: 0;
    height: 51px;
    &:hover {
      color: $brand-color !important;
      background-color: $primary-color-700 !important;
      opacity: 90%;
      cursor: pointer;
    }
  }
  @include mobile {
    padding: 12px;
  }
}
.audio {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  @include mobile {
    width: 100% !important;
  }
  audio {
    width: 80% !important;
  }
}
