// Theme Palette Colors
$primary-color-800: #470e0a;
$primary-color-700: #8e1d14;
$primary-color: #d52b1e;
$primary-color-500: #dc4e44;
$primary-color-400: #ea958e;
$primary-color-300: #f7d5d2;
$brand-color: #fff1ef;
$neutral-black: #240806;
$neutral-grey-500: #6a6767;
$neutral-grey-400: #bec2ca;
$neutral-grey-300: #dad6d5;
$neutral-grey-200: #f0efee;
$neutral-grey-100: #f6f5f5;
